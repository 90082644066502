import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PropsWithChildren } from 'react';
import { language_enum } from '../../../../libs/generated/enums/src';
import { useLanguage } from './LanguageProvider';

// When adding a new locale: import it here
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';

dayjs.extend(relativeTime);

/**
 * Sets the Language for the DatePicker component where the current language has to be mapped to a language adapter of the underlying date library.
 */
export const DatePickerLanguageProvider = ({ children }: PropsWithChildren<{}>) => {
  const { language } = useLanguage();

  const getDateLocale = (language: language_enum) => {
    const locales = {
      [language_enum.deDE]: 'de',
      [language_enum.enGB]: 'en-gb',
      // @ts-ignore
      DEBUG: 'en-gb',
    } satisfies Record<language_enum, string>;

    return locales[language];
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getDateLocale(language)}>
      {children}
    </LocalizationProvider>
  );
};

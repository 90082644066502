import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import {
  climate_risk_type_enum,
  EsgAnalysisGetFilteredBuildingsQuery,
  EsgAnalysisGetPortfolioQuery,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { getAnalyzedBuildingsWhere } from '@predium/graphql-where-client';
import { useState } from 'react';
import { ClimateRiskGraph } from '../../../components/data-visialization/esg-analysis/ClimateRiskChart';
import { GET_PORTFOLIO } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { getBuildingWhereFilters } from '../../../utils/getWhereFilters';
import { DisplayViewType } from '../Components/EsgAnalysisViewSwitcher';
import EsgAnalysisBuildingListTab from '../EsgAnalysisBuildingListTab';
import EsgAnalysisSection from '../EsgAnalysisSection';
import { FloorAreaBySubBuildingClass } from '../EsgAnalysisUtil';
import { ClimateRiskData, CurrentPageView, EsgAnalysisTab } from './PortfolioAnalysisHeader';

type Props = {
  buildings: EsgAnalysisGetFilteredBuildingsQuery;
  portfolios: EsgAnalysisPortfolioFragment[];
  currentPageView?: CurrentPageView;
  setFloorAreaByBuildingClass: (data: FloorAreaBySubBuildingClass) => void;
  currentTab: EsgAnalysisTab;
  showTotal: boolean;
  currentView: DisplayViewType;
  benchmarkId: number;
};

export default function PortfolioAnalysisBody({
  buildings,
  portfolios,
  currentPageView,
  setFloorAreaByBuildingClass,
  currentTab,
  showTotal,
  benchmarkId,
  currentView,
}: Props) {
  const { filters } = useEsgAnalysisFilters();

  const [climateRisks, setClimateRiskData] = useState<ClimateRiskData>([]);

  const constructionYear = Math.min(...buildings.building.map((building) => building.year_constructed));
  const currentYear = new Date().getFullYear();

  const { data, loading, previousData } = useQuery<EsgAnalysisGetPortfolioQuery>(GET_PORTFOLIO, {
    variables: {
      buildingWhere: getAnalyzedBuildingsWhere(getBuildingWhereFilters(filters), currentYear),
    },
    onCompleted: (data) => {
      setFloorAreaByBuildingClass(data.getAnalyzedBuildings.floorAreaBySubBuildingClass);
      setClimateRiskData(data.getAnalyzedBuildings.climateRisks);
    },
    fetchPolicy: 'cache-and-network',
  });

  const climateRisksData = climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  if (currentTab === 'demand') {
    if (currentView === 'dashboard') {
      return (
        <Box sx={{ pt: 5 }}>
          <EsgAnalysisSection
            showTotal={showTotal}
            benchmarkId={benchmarkId}
            portfolios={portfolios}
            constructionYear={constructionYear}
            currentPageView={currentPageView}
            loading={loading}
            analyzedBuildings={data?.getAnalyzedBuildings ?? previousData?.getAnalyzedBuildings}
          />
        </Box>
      );
    }

    return (
      <Box sx={{ pt: 5 }}>
        <EsgAnalysisBuildingListTab filteredBuildings={buildings.building} showTotal={showTotal} />
      </Box>
    );
  }

  if (currentTab === 'climate_risks') {
    return (
      <Box sx={{ pt: 5 }}>
        <Grid item xs={12}>
          <ClimateRiskGraph climateRisks={climateRisksData} />
        </Grid>
      </Box>
    );
  }

  return null;
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DataCollectionEconomicUnitFragment } from '@predium/client-graphql';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import NavigationSelector from './NavigationMultiSelector';
import { AutoCompleteValue } from './PortfolioSelector';

type Props = {
  economicUnits: DataCollectionEconomicUnitFragment[];
  filtersComponent: ReactNode;
};

const ALL_ECONOMIC_UNITS = 'allEconomicUnits';

export default function EconomicUnitsSelector({ economicUnits, filtersComponent }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const { filters, replaceFilter, resetFilter } = useEsgAnalysisFilters();

  /**
   * Creates each option for the economicUnits select
   */
  const mapToEconomicUnitSelectOption = (economicUnit: DataCollectionEconomicUnitFragment): AutoCompleteValue => {
    return {
      value: economicUnit.id,
      label: economicUnit.name,
    };
  };

  const getEconomicUnitFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return economicUnits.filter((economicUnit) => ids.includes(economicUnit.id)).map(mapToEconomicUnitSelectOption);
  };

  const onEconomicUnitSelected = (selectedEconomicUnits: AutoCompleteValue[]) => {
    const previousEcoUnits = getEconomicUnitFromIds(filters.economicUnitIds);

    const newlySelectedEcoUnit: AutoCompleteValue[] | undefined =
      previousEcoUnits &&
      selectedEconomicUnits.filter(
        (ecoUnit) => !previousEcoUnits.some((prevEcoUnit) => prevEcoUnit.value === ecoUnit.value),
      );

    if (newlySelectedEcoUnit && newlySelectedEcoUnit.length > 0) {
      trackEvent('ESG_ECONOMIC_UNIT_SELECTED', {
        economic_unit_id: newlySelectedEcoUnit[0].value,
      });
    }

    replaceFilter(
      EsgAnalysisFiltersKeys.economicUnitIds,
      selectedEconomicUnits.map((ecoUnit) => ecoUnit.value as number),
    );
  };

  const economicUnitOptions = economicUnits.map(mapToEconomicUnitSelectOption);
  const filteredEconomicUnits =
    filters.economicUnitIds && filters.economicUnitIds.length > 0
      ? getEconomicUnitFromIds(filters.economicUnitIds)
      : [];

  const count = filteredEconomicUnits.length;
  const selectorLabel =
    filteredEconomicUnits.length > 1
      ? `${count} ${t('General_EconomicUnit', {
          count: count,
        })}`
      : filteredEconomicUnits[0]?.label ?? t('General_AllEconomicUnits');

  return (
    <NavigationSelector
      onOptionSelected={onEconomicUnitSelected}
      options={economicUnitOptions}
      filtersComponent={filtersComponent}
      filteredItems={filteredEconomicUnits}
      selectorLabel={selectorLabel}
      noDataText={t('EsgAnalysisFilterBarEconomicUnits_NoEconomicUnitsFound')}
      handleClear={() => {
        resetFilter(EsgAnalysisFiltersKeys.economicUnitIds);
        resetFilter(EsgAnalysisFiltersKeys.buildingIds);
        trackEvent('ESG_ECONOMIC_UNIT_SELECTED', {
          economic_unit_id: ALL_ECONOMIC_UNITS,
        });
      }}
      placeholder={t('General_SearchEconomicUnitPlaceholder')}
    />
  );
}

import { graphql } from '@predium/client-graphql';

graphql(`
  fragment EsgAnalysisPortfolio on portfolio {
    id
    name
  }
`);

graphql(`
  fragment EsgAnalysisGetBuildings on building {
    id
    year_constructed
    economic_unit {
      portfolio {
        ...EsgAnalysisPortfolio
      }
    }
    areas(where: { area_type_id: { _eq: NUF } }) {
      value
      type_of_use_id
      class_of_use_id
      area_type_id
    }
    address {
      street
      city
      postal_code
      country_id
    }
    active_building_model {
      energy_paths(where: { year: { _eq: $currentYear } }) {
        co2_emissions
        co2_costs
        efficiency_class_id
        eu_taxonomy_compliance_id
      }
      energy_primary
      energy_final
    }
  }
`);

graphql(`
  fragment EsgAnalysisGetBuilding on building {
    id
    year_constructed
    address {
      street
      postal_code
      city
      country_id
      mapbox_building_ids
    }
    economic_unit {
      portfolio {
        ...EsgAnalysisPortfolio
      }
    }
    responsible_user {
      first_name
      last_name
    }
    active_building_model {
      energy_paths(where: { year: { _eq: $currentYear } }) {
        co2_emissions
        co2_costs
        efficiency_class_id
        eu_taxonomy_compliance_id
      }
    }
    areas(where: { area_type_id: { _eq: NUF } }) {
      value
      area_type_id
      type_of_use_id
      class_of_use_id
    }
  }
`);

graphql(`
  fragment EsgAnalysisGetBenchmarkBuildings on BuildingsBenchmarkOutput {
    averageFinalEnergy
    averagePrimaryEnergy
    averageHeatingDemand
    averageCo2Emissions
    averageCo2Costs
    averageEnergyCosts
    crremAnalysis {
      strandingYear
      yearOfExcedance
    }
  }
`);

graphql(`
  fragment EsgAnalysisGetBuildingsPortfolioAndAddress on building {
    id
    economic_unit {
      id
      portfolio {
        ...EsgAnalysisPortfolio
      }
    }
    address {
      street
    }
  }
`);

graphql(`
  fragment EsgAnalysisGetBuildingsForSingleBuilding on building {
    id
    year_constructed
    address {
      street
      postal_code
      city
      country_id
      mapbox_building_ids
    }
    economic_unit {
      portfolio {
        ...EsgAnalysisPortfolio
      }
    }
    responsible_user {
      first_name
      last_name
    }
    areas(where: { area_type_id: { _eq: NUF } }) {
      value
      area_type_id
      type_of_use_id
      class_of_use_id
    }
  }
`);

graphql(`
  fragment AnalyzeBuildingsCrremAnalysis on AnalyzeBuildingsCrremOutput {
    co2TargetPath
    energyTargetPath
    co2Path {
      m2
    }
    energyPath {
      m2
    }
    strandingYear
    yearOfExcedance
    totalCumulativeExcessEmissions
  }
`);

graphql(`
  fragment AnalyzeBuildingCrremAnalysis on AnalyzeBuildingCrremOutput {
    co2TargetPath
    energyTargetPath
    co2Path {
      m2
    }
    energyPath {
      m2
    }
    strandingYear
    yearOfExcedance
    cumulativeExcessEmissions
  }
`);

graphql(`
  fragment BuildingCRREMConsumptionAnalysisOutput on GetBuildingCRREMConsumptionAnalysisOutput {
    co2TargetPath
    energyTargetPath
    co2Path {
      m2
    }
    energyPath {
      m2
    }
    strandingYear
    yearOfExcedance
    cumulativeExcessEmissions
  }
`);

export const GET_FILTERED_BUILDINGS = graphql(`
  query EsgAnalysisGetFilteredBuildings($buildingWhere: building_bool_exp!, $currentYear: Int!) {
    building(where: $buildingWhere) {
      ...EsgAnalysisGetBuildings
    }
  }
`);

export const GET_BUILDING_FILTER_VALUES = graphql(`
  query EsgAnalysisGetAllBuildingFilterValues($currentYear: Int!) {
    energy_system_consumer_route(distinct_on: energy_source_type_id) {
      energy_source_type_id
    }
    energy_path_tax_bracket: energy_path(
      where: { year: { _eq: $currentYear }, building_model: { active_for_building: {} } }
      distinct_on: [tax_bracket_id]
    ) {
      tax_bracket_id
    }
    energy_path_efficiency_class: energy_path(
      where: { year: { _eq: $currentYear }, building_model: { active_for_building: {} } }
      distinct_on: [efficiency_class_id]
    ) {
      efficiency_class_id
    }
    energy_path_eu_taxonomy_compliance: energy_path(
      where: { year: { _eq: $currentYear }, building_model: { active_for_building: {} } }
      distinct_on: [eu_taxonomy_compliance_id]
    ) {
      eu_taxonomy_compliance_id
    }
    sub_building_class: area(distinct_on: class_of_use_id) {
      class_of_use_id
    }
    building_state_id: building(distinct_on: building_state_id) {
      building_state_id
    }
    monument_protection: building(distinct_on: monument_protection) {
      monument_protection
    }
    heritage_district: building(distinct_on: heritage_district) {
      heritage_district
    }
    milieu_protection: building(distinct_on: milieu_protection) {
      milieu_protection
    }
    leasehold: building(distinct_on: leasehold) {
      leasehold
    }
  }
`);

export const GET_ALL_BUILDINGS_LIST = graphql(`
  query EsgAnalysisGetAllBuildingList {
    building(order_by: { address: { street: asc } }) {
      ...EsgAnalysisGetBuildingsPortfolioAndAddress
    }
  }
`);

export const GET_ALL_BUILDINGS = graphql(`
  query EsgAnalysisGetAllBuildings {
    building(order_by: { address: { street: asc } }) {
      ...EsgAnalysisGetBuildingsForSingleBuilding
    }
  }
`);

export const GET_BENCHMARK = graphql(`
  query EsgAnalysisGetBenchmark($portfolioId: Int!) {
    getBenchmark(portfolioId: $portfolioId) {
      ...EsgAnalysisGetBenchmarkBuildings
    }
  }
`);

export const GET_PORTFOLIO = graphql(`
  query EsgAnalysisGetPortfolio($buildingWhere: BuildingWhere!) {
    getAnalyzedBuildings(where: $buildingWhere) {
      analyzedBuildingsCount
      co2CostPath {
        total
        m2
      }
      energyCostPath {
        total
        m2
      }
      floorAreaBySubBuildingClass {
        subBuildingClass
        area
      }
      buildingsByEfficiencyClass {
        count
        efficiencyClass
      }
      buildingsByTaxBracket {
        count
        taxBracket {
          taxBracket
          landlordProportion
        }
      }
      buildingsByEnergySourceType {
        count
        energySourceType
      }
      buildingsByEUTaxonomyCompliance {
        euTaxonomyCompliance
        count
      }
      averageTaxBracket {
        landlordProportion
      }
      averageEfficiencyClass
      averageFinalEnergy
      averagePrimaryEnergy
      averageHeatingDemand
      averageCo2Emissions
      energyDataBySourceType {
        energySourceType
        energyConsumptionTotal
        energyConsumptionM2
      }
      climateRisks {
        type
        timespan
        value
      }
      scope123Co2Emissions {
        year
        data {
          scope1 {
            co2Emissions
            co2EmissionsTotal
          }
          scope2 {
            co2Emissions
            co2EmissionsTotal
          }
          scope3 {
            co2Emissions
            co2EmissionsTotal
          }
        }
      }
      buildingsGeneralData {
        area
        address
        buildingId
        co2Intensity
        typeOfUse
        finalEnergy
        year
        mainEnergySourceType
      }
      crremAnalysis {
        ...AnalyzeBuildingsCrremAnalysis
      }
    }
  }
`);

export const GET_ANALYZED_BUILDING = graphql(`
  query EsgAnalysisBuildingGetBuilding($buildingId: Int!, $year: Int!) {
    getAnalyzedBuilding(building_id: $buildingId, year: $year) {
      co2CostPath {
        total
        m2
      }
      energyCostPath {
        total
        m2
      }
      energyConsumers {
        energySourceType
        energyConsumptionM2
        energyConsumptionTotal
      }
      taxBracket {
        landlordProportion
      }
      efficiencyClass
      euTaxonomyCompliance
      finalEnergy
      primaryEnergy
      heatingDemand
      co2Emissions
      climateRisks {
        timespan
        type
        value
      }
      floorAreaBySubBuildingClass {
        subBuildingClass
        area
      }
      scope123Co2Emissions {
        scope1 {
          co2Emissions
          co2EmissionsTotal
        }
        scope2 {
          co2Emissions
          co2EmissionsTotal
        }
        scope3 {
          co2Emissions
          co2EmissionsTotal
        }
      }
      crremAnalysis {
        ...AnalyzeBuildingCrremAnalysis
      }
    }
  }
`);

export const EXPORT_ESG_ANALYSIS_PDF = graphql(`
  query EsgAnalysisExportPdf($buildingId: Int!, $title: String, $description: String) {
    getEsgAnalysisPdf(buildingId: $buildingId, title: $title, description: $description) {
      body
    }
  }
`);

export const ESG_ANALYSIS_CONSUMPTION_ANALYSIS = graphql(`
  query EsgAnalysisConsumptionAnalysis($buildingId: Int!, $from: Float!, $to: Float!) {
    getConsumptionAnalysis(building_id: $buildingId, from: $from, to: $to) {
      data {
        averageAbsolute
        average
        series {
          count
          from
          to
          sumAbsolute
          sum
        }
        type
      }
    }
  }
`);

export const ESG_ANALYSIS_CONSUMPTION_ALLOCATION = graphql(`
  query EsgAnalysisConsumptionAllocation($buildingId: Int!, $from: Float!, $to: Float!) {
    getConsumptionAllocation(building_id: $buildingId, from: $from, to: $to) {
      carbonEmissions {
        intensity
        intensityAbsolute
        scope1 {
          percentage
          value
        }
        scope2 {
          percentage
          value
        }
        scope3 {
          percentage
          value
        }
      }
      co2Tax {
        costShare
        total
        totalAbsolute
        forecastItems {
          landlordShare
          landlordSharePercentage
          tenantShare
          tenantSharePercentage
          year
        }
      }
      energyConsumptions {
        items {
          percentage
          type
          value
          valueAbsolute
        }
        total
        totalAbsolute
        type
      }
    }
  }
`);

export const GET_BUILDING_CRREM_CONSUMPTION_ANALYSIS = graphql(`
  query getBuildingCRREMConsumptionAnalysis($buildingId: Int!, $year: Int!) {
    getBuildingCRREMConsumptionAnalysis(building_id: $buildingId, year: $year) {
      ...BuildingCRREMConsumptionAnalysisOutput
    }
  }
`);

export const GET_BUILDING_CONSUMPTION_KPIs = graphql(`
  query getEsgConsumptionAnalysisKPIs($buildingId: Int!, $year: Int!) {
    getEsgConsumptionAnalysisKPIs(buildingId: $buildingId, year: $year) {
      co2Intensity
      co2IntensityAbsolute
      co2Tax
      co2TaxAbsolute
      efficiencyClass
      energyCost
      energyCostAbsolute
      euTaxonomy
      finalEnergy
      finalEnergyAbsolute
      primaryEnergy
      primaryEnergyAbsolute
    }
  }
`);

/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Autocomplete,
  Box,
  Button,
  ListItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EsgAnalysisGetAllBuildingsQuery,
  EsgAnalysisGetBuildingsForSingleBuildingFragment,
} from '@predium/client-graphql';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import { AutoCompleteValue } from './NavigationMultiSelector';

export const CustomPaper = styled(Paper)(() => ({
  position: 'relative',
  overflow: 'hidden !important',
  height: 410,
  boxShadow: 'none !important',
}));

type Props = {
  buildings: EsgAnalysisGetAllBuildingsQuery['building'];
  selectedBuilding: EsgAnalysisGetBuildingsForSingleBuildingFragment;
  onBuildingSelected: (building: AutoCompleteValue) => void;
};
export default function BuildingSelector({ buildings, selectedBuilding, onBuildingSelected }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
        setShowAutocomplete(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autocompleteRef]);

  const handleBuildingChange = (_: SyntheticEvent<Element, Event>, newValue: AutoCompleteValue) => {
    setShowAutocomplete(false);
    onBuildingSelected(newValue);
  };

  const handleTypographyClick = () => {
    setShowAutocomplete(!showAutocomplete);
  };

  /**
   * Creates each option for the building select
   */
  const mapToBuildingSelectOption = (
    building: EsgAnalysisGetBuildingsForSingleBuildingFragment,
  ): { value: number; label: string } => {
    return {
      value: building.id,
      label: `${building.address.street}, ${building.address.city}`,
    };
  };

  const buildingOptions = buildings?.map(mapToBuildingSelectOption) ?? [];
  const defaultBuilding = buildingOptions.find((option) => option.value === selectedBuilding?.id) || null;

  return (
    <Box ml={1} position={'relative'} ref={autocompleteRef}>
      <Stack alignItems={'center'}>
        <Button
          onClick={handleTypographyClick}
          sx={{
            color: 'text.secondary',
            background: showAutocomplete ? theme.palette.action.selected : '',
            width: 'fit-content',
          }}
        >
          <Typography variant="h4">{`${selectedBuilding.address.street}, ${selectedBuilding.address.city}`}</Typography>
          <Iconify icon={ICONS.CHEVRON_DOWN} width={24} height={24} />
        </Button>
      </Stack>

      {showAutocomplete && (
        <Box
          sx={{
            position: 'absolute',
            mt: 1,
            '.MuiAutocomplete-popper': {
              zIndex: 2,
              background: theme.palette.common.white,
              pt: 1,
            },
            height: 480,
            width: 480,
            border: '1px solid',
            borderColor: 'divider',
            background: theme.palette.common.white,
            zIndex: 2,
            borderRadius: '8px',
            p: 1,
            boxShadow: theme.shadows[3],
            '.MuiAutocomplete-listbox': {
              scrollbarWidth: 'thin',
              maxHeight: '100%',
              pl: 0,
            },
          }}
        >
          {showAutocomplete && (
            <Autocomplete
              options={buildingOptions}
              open={showAutocomplete}
              getOptionLabel={(option) => option.label}
              value={mapToBuildingSelectOption(selectedBuilding)}
              onChange={handleBuildingChange}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'text.secondary',
                      '&.Mui-focused fieldset': {
                        border: '1px solid',
                        borderColor: 'divider',
                      },
                    },
                    maxWidth: 500,
                  }}
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                    startAdornment: (
                      <Iconify icon={ICONS.MAGNIFY} width={24} height={24} sx={{ color: 'text.secondary' }} />
                    ),
                  }}
                  placeholder={t('General_SearchBuildingPlaceholder')}
                />
              )}
              defaultValue={defaultBuilding ?? { value: -1, label: '' }}
              disableClearable
              openOnFocus
              disablePortal
              includeInputInList
              sx={{ mt: 0.5, position: 'relative' }}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.value}>
                  <Typography>{option.label}</Typography>
                </ListItem>
              )}
              PaperComponent={(props) => <CustomPaper {...props}>{props.children}</CustomPaper>}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

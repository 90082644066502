import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  climate_risk_type_enum,
  EsgAnalysisGetBuildingsForSingleBuildingFragment,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClimateRiskGraph } from '../../../components/data-visialization/esg-analysis/ClimateRiskChart';
import { GET_ANALYZED_BUILDING } from '../../../graphql/EsgAnalysis.queries';
import useSessionData from '../../../hooks/useSessionData';
import { ENTIRE_COMPANY } from '../Components/EsgAnalysisBenchmark';
import BuildingConsumptionAnalysisTab from '../ConsumptionAnalysis/BuildingConsumptionAnalysisTab';
import { FloorAreaBySubBuildingClass } from '../EsgAnalysisUtil';
import { ClimateRiskData, EsgAnalysisTab } from '../Portfolio/PortfolioAnalysisHeader';
import EsgAnalysisBuildingSectionDemandTab from './BuildingAnalysisDemandTab';

type Props = {
  currentTab: EsgAnalysisTab;
  selectedBuilding: EsgAnalysisGetBuildingsForSingleBuildingFragment;
  setFloorAreaByBuildingClass: (data: FloorAreaBySubBuildingClass) => void;
  portfolios: EsgAnalysisPortfolioFragment[];
  selectedReportingYear: number;
  showTotal: boolean;
  benchmarkId: number | undefined;
};

export default function EsgBuildingBody({
  showTotal,
  currentTab,
  selectedBuilding,
  setFloorAreaByBuildingClass,
  portfolios,
  selectedReportingYear,
  benchmarkId,
}: Props) {
  const { t } = useTranslation();
  const { serverSideFeatureFlags } = useSessionData();

  const [climateRisks, setClimateRiskData] = useState<ClimateRiskData>([]);

  const currentYear = new Date().getFullYear();
  const isConsumptionAnalysisEnabled = serverSideFeatureFlags.CONSUMPTION_ANALYSIS;

  const {
    data: analyzeBuildingData,
    loading,
    previousData,
  } = useQuery(GET_ANALYZED_BUILDING, {
    fetchPolicy: 'cache-and-network',
    variables: { buildingId: selectedBuilding.id, year: currentYear },
    onCompleted: (data) => {
      setFloorAreaByBuildingClass(data.getAnalyzedBuilding.floorAreaBySubBuildingClass);
      setClimateRiskData(data.getAnalyzedBuilding.climateRisks);
    },
  });

  const climateRisksData = climateRisks.map((risk) => ({
    ...risk,
    type: climate_risk_type_enum[risk.type],
  }));

  const benchmarks = useMemo(
    () => [
      { id: ENTIRE_COMPANY, name: t('EsgAnalysisBenchmark_EntireCompany') },
      ...portfolios.map((portfolio) => ({
        id: portfolio.id,
        name: `Portfolio ${portfolio.name}`,
      })),
    ],
    [portfolios, t],
  );

  const selectedBenchmarkName = benchmarks.find((benchmark) => benchmark.id === benchmarkId)?.name;

  if (currentTab === 'demand') {
    return (
      <Box sx={{ pt: 5 }}>
        <EsgAnalysisBuildingSectionDemandTab
          building={selectedBuilding}
          portfolios={portfolios}
          benchmarkId={benchmarkId}
          showTotal={showTotal}
          analyzeBuildingData={analyzeBuildingData?.getAnalyzedBuilding ?? previousData?.getAnalyzedBuilding}
          loading={loading}
        />
      </Box>
    );
  }

  if (currentTab === 'climate_risks') {
    return (
      <Box sx={{ pt: 5 }}>
        <ClimateRiskGraph climateRisks={climateRisksData} />
      </Box>
    );
  }

  if (isConsumptionAnalysisEnabled && currentTab === 'consumption') {
    return (
      <Box sx={{ pt: 5 }}>
        <BuildingConsumptionAnalysisTab
          building={selectedBuilding}
          showTotal={showTotal}
          selectedBenchmarkName={selectedBenchmarkName ?? ''}
          selectedReportingYear={selectedReportingYear}
          selectedBuildingId={selectedBuilding.id}
        />
      </Box>
    );
  }

  return null;
}

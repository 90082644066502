/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import {
  data_source_type_enum,
  DataCollectionBuildingEnvelopeFragment,
  DataCollectionBuildingModelFragment,
  envelope_type_enum,
  insulation_material_category_enum,
  insulation_method_enum,
} from '@predium/client-graphql';
import { getDefaultRangeForCurrentOrientation } from '@predium/client-lookup';
import { mToCm } from '@predium/utils';
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Unavailable from '../../../components/Unavailable';
import { FormProvider } from '../../../components/hook-form';
import { FormRef, NestedForm } from '../../../pages/DataCollection/DataCollectionBuilding';
import { calculateTotalCount, calculateTotalEnvelopeEditCount } from '../../../utils/formUtils';
import { InconsistentDataAlert } from './Common/BuildingAlerts';
import useBuilding from './Context/useBuilding';
import { areFieldArraysDirty } from './Envelopes/Envelope.util';
import { BottomEnvelopesSection } from './Envelopes/EnvelopeSection/BottomEnvelopesSection';
import { MiddleEnvelopesSection } from './Envelopes/EnvelopeSection/MiddleEnvelopesSection';
import { TopEnvelopesSection } from './Envelopes/EnvelopeSection/TopEnvelopesSection';

const commonFields = ['area', 'u_value', 'data_source_type_id', 'envelope_type_id'];
const insulationFields = [
  'insulation_material_category',
  'insulation_thickness',
  'insulation_lambda',
  'insulation_method',
  'insulation_material_name',
];
const constructionFields = [
  'base_construction_material_name',
  'base_construction_thickness',
  'base_construction_lambda',
];

const doorFields = [...commonFields, 'insulation_material_name', 'orientation'];
const windowFields = [...commonFields, 'insulation_material_name', 'inclination', 'orientation', 'insulation_lambda'];
const pitchedRoofFields = [...commonFields, ...constructionFields, 'inclination', 'orientation', ...insulationFields];
const otherFields = [...commonFields, ...constructionFields, ...insulationFields];

type Insulation = {
  has_insulation?: boolean;
  insulation_deleted?: boolean;
  insulation_material_name: string;
  insulation_material_custom?: string | 'custom';
  insulation_thickness: number | undefined;
  insulation_material_category: insulation_material_category_enum | '';
  insulation_lambda: number | undefined;
  insulation_method: insulation_method_enum | '';
};

type BaseEnvelopeFormValuesProps = {
  envelope_id?: number | null;
  id?: string | null; //RHF field id
  create?: boolean;
  delete?: boolean;
  data_source_type_id: data_source_type_enum;
  envelope_type: envelope_type_enum;
  area: number | undefined;
  u_value: number | undefined;
};

export type WindowFormValuesProps = BaseEnvelopeFormValuesProps & {
  inclination: number | undefined;
  orientation: string;
  insulation_lambda: number | undefined;
  insulation_material_name?: string;
};

export type DoorFormValuesProps = BaseEnvelopeFormValuesProps & {
  orientation: string;
  insulation_material_name?: string;
};

export type OtherFormValuesProps = BaseEnvelopeFormValuesProps &
  Insulation & {
    base_construction_thickness: number | undefined;
    base_construction_lambda: number | undefined;
    base_construction_material_name?: string;
    orientation?: string;
  };

export type PitchedRoofFormValuesProps = OtherFormValuesProps & {
  inclination: number | undefined;
  orientation: string;
  base_construction_lambda: number | undefined;
};

export type EnvelopeFormValues = {
  PITCHED_ROOF?: PitchedRoofFormValuesProps[];
  FLAT_ROOF?: OtherFormValuesProps[];
  TOP_FLOOR_CEILING?: OtherFormValuesProps[];

  WALL?: OtherFormValuesProps[];
  WINDOW?: WindowFormValuesProps[];
  DOOR?: DoorFormValuesProps[];

  FLOOR?: OtherFormValuesProps[];
  BASEMENT_CEILING?: OtherFormValuesProps[];
};

export const createEnvelopeDefaultValues = (
  envelopeType: envelope_type_enum,
): {
  create: boolean;
  envelope_type: envelope_type_enum;
  data_source_type_id: data_source_type_enum;
  has_insulation: boolean;
} => ({
  create: true,
  envelope_type: envelopeType,
  data_source_type_id: data_source_type_enum.MANUAL,
  has_insulation: true,
});

function setInsulationData(
  item: DataCollectionBuildingEnvelopeFragment,
  envelope_type: envelope_type_enum,
  result: PitchedRoofFormValuesProps,
) {
  if (otherEnvelopeTypes.includes(envelope_type) || envelope_type === envelope_type_enum.PITCHED_ROOF) {
    const {
      insulation_method_id,
      insulation_material_name,
      insulation_material_category_id,
      insulation_thickness,
      insulation_lambda,
    } = item;

    result['has_insulation'] =
      !!insulation_method_id ||
      !!insulation_material_name ||
      !!insulation_material_category_id ||
      !!insulation_thickness ||
      !!insulation_lambda;

    result['insulation_method'] = item.insulation_method_id ?? '';

    //@ts-ignore
    result['insulation_material_category'] = item.insulation_material_category_id;
  }
}

const envelopeTypes: envelope_type_enum[] = Object.values(envelope_type_enum);
const enumsToRemove = [envelope_type_enum.WINDOW, envelope_type_enum.DOOR, envelope_type_enum.PITCHED_ROOF];
const otherEnvelopeTypes = envelopeTypes.filter((enumValue) => !enumsToRemove.includes(enumValue));

function filterObjectsByEnvelopeType(
  inputData: DataCollectionBuildingModelFragment['envelope_units'],
  fieldsToInclude: string[],
  envelope_type: envelope_type_enum,
) {
  fieldsToInclude = envelope_type === envelope_type_enum.WALL ? [...fieldsToInclude, 'orientation'] : fieldsToInclude;
  return inputData
    .filter((item) => item.envelope_type_id === envelope_type)
    .map((item) =>
      fieldsToInclude.reduce((result, field) => {
        if (item.hasOwnProperty(field)) {
          const itemField = field as keyof typeof item;

          if (item[itemField] && typeof item[itemField] === 'number') {
            if (field === 'insulation_thickness' || field === 'base_construction_thickness') {
              // Backend values are treated in meters, and we want to show values in cm, so doing the conversion here.

              result[itemField] = mToCm(item[field] ?? 0);
            } else if (field === 'insulation_lambda' || field === 'base_construction_lambda') {
              result[itemField] = parseFloat((item[itemField] as number).toFixed(3));
            } else {
              result[itemField] = parseFloat((item[itemField] as number).toFixed(2));
            }
          } else {
            result[itemField] = item[itemField];
          }
          // @ts-ignore Dont know where these properties come from but I am not gonna change them now.
          result['envelope_id'] = item.id;
          // @ts-ignore Dont know where these properties come from but I am not gonna change them now.
          result['envelope_type'] = item.envelope_type_id;
          result['orientation'] =
            // @ts-ignore
            item.orientation !== null ? getDefaultRangeForCurrentOrientation(item.orientation) : '';
          // There are multiple properties missing in this type. Its not good.
          setInsulationData(item, envelope_type, result as unknown as PitchedRoofFormValuesProps);
        }

        delete result.envelope_type_id;
        delete result.insulation_material_category_id;

        return result;
      }, {} as Record<keyof (typeof inputData)[number], any> & { envelope_type: envelope_type_enum; envelope_id: number }),
    );
}

export const CUSTOM = 'custom';

const BuildingEnvelope = forwardRef<FormRef<EnvelopeFormValues>, NestedForm & { heatingDemandComponent: ReactNode }>(
  ({ buildingModel, isShown, setFormState, heatingDemandComponent }, ref) => {
    const { t } = useTranslation();
    const { hasEditAccess } = useBuilding();

    const REQUIRED_MESSAGE = t('General_Required');
    const THICKNESS_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_ThicknessRangeMessage');
    const WALL_THICKNESS_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_WallThicknessRangeMessage');
    const GVALUE_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_GValueRangeMessage');
    const UVALUE_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_UValueRangeMessage');
    const INSULATION_THERMAL_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_InsulationThermalRangeMessage');
    const CONSTRUCTION_THERMAL_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_ConstructionThermalRangeMessage');
    const ORIENTATION_RANGE_MESSAGE = t('DataCollection_EnvelopesEditForm_OrientationRangeMessage');

    const [shouldValidate, setShouldValidate] = useState(false);

    const { envelope_units: envelopeUnits } = buildingModel;

    // Generate default values for different envelope types .
    const defaultValues = useMemo<EnvelopeFormValues>(() => {
      const filteredData = otherEnvelopeTypes.reduce((acc, envelopeType) => {
        const filteredData = filterObjectsByEnvelopeType(envelopeUnits, otherFields, envelopeType);
        // @ts-ignore @bharat FIXME: This looks like a bug because an array is expected but the function just returns an item.
        acc[envelopeType] = filteredData || otherEnvelopesDefaultValues(envelopeType);
        return acc;
      }, {} as EnvelopeFormValues);

      return {
        ...filteredData,

        WINDOW: filterObjectsByEnvelopeType(envelopeUnits, windowFields, envelope_type_enum.WINDOW),
        DOOR: filterObjectsByEnvelopeType(envelopeUnits, doorFields, envelope_type_enum.DOOR),
        PITCHED_ROOF: filterObjectsByEnvelopeType(envelopeUnits, pitchedRoofFields, envelope_type_enum.PITCHED_ROOF),
      } as any;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowFields, doorFields, otherFields, envelopeUnits]);

    const envelopeCommonSchema = {
      area: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    };

    const insulationSchema = {
      insulation_material_name: Yup.string().when(['has_insulation', 'insulation_deleted', 'delete'], {
        is: (hasInsulation: boolean, insulationDeleted: boolean, envelopeDeleted: boolean) =>
          hasInsulation && !insulationDeleted && !envelopeDeleted,
        then: Yup.string().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        otherwise: Yup.string().nullable(),
      }),
      insulation_material_custom: Yup.string().when(['insulation_deleted', 'insulation_material_name', 'delete'], {
        is: (insulationDeleted: boolean, insulationMaterialName: string, envelopeDeleted: boolean) =>
          !insulationDeleted && insulationMaterialName === CUSTOM && !envelopeDeleted,
        then: Yup.string().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        otherwise: Yup.string().nullable(),
      }),
      insulation_method: Yup.string().when(['has_insulation', 'insulation_deleted', 'delete'], {
        is: (hasInsulation: boolean, insulationDeleted: boolean, envelopeDeleted: boolean) =>
          hasInsulation && !insulationDeleted && !envelopeDeleted,
        then: Yup.string()
          .oneOf(Object.keys(insulation_method_enum), REQUIRED_MESSAGE)
          .typeError(REQUIRED_MESSAGE)
          .required(REQUIRED_MESSAGE),
        otherwise: Yup.string().nullable(),
      }),
      insulation_lambda: Yup.number().when(['has_insulation', 'insulation_deleted', 'delete'], {
        is: (hasInsulation: boolean, insulationDeleted: boolean, envelopeDeleted: boolean) =>
          hasInsulation && !insulationDeleted && !envelopeDeleted,
        then: Yup.number()
          .typeError(REQUIRED_MESSAGE)
          .required(REQUIRED_MESSAGE)
          .min(0, INSULATION_THERMAL_RANGE_MESSAGE)
          .max(0.05, INSULATION_THERMAL_RANGE_MESSAGE),
        otherwise: Yup.number().nullable(),
      }),
      insulation_thickness: Yup.number().when(['has_insulation', 'insulation_deleted', 'delete'], {
        is: (hasInsulation: boolean, insulationDeleted: boolean, envelopeDeleted: boolean) =>
          hasInsulation && !insulationDeleted && !envelopeDeleted,
        then: Yup.number()
          .typeError(REQUIRED_MESSAGE)
          .required(REQUIRED_MESSAGE)
          .min(0, THICKNESS_RANGE_MESSAGE)
          .max(60, THICKNESS_RANGE_MESSAGE),
        otherwise: Yup.number().nullable(),
      }),
      insulation_material_category: Yup.string().when(['has_insulation', 'insulation_deleted', 'delete'], {
        is: (hasInsulation: boolean, insulationDeleted: boolean, envelopeDeleted: boolean) =>
          hasInsulation && !insulationDeleted && !envelopeDeleted,
        then: Yup.string()
          .oneOf(Object.keys(insulation_material_category_enum), REQUIRED_MESSAGE)
          .typeError(REQUIRED_MESSAGE)
          .required(REQUIRED_MESSAGE),
        otherwise: Yup.string().nullable(),
      }),
    };

    const otherEnvelopeSchema = {
      ...envelopeCommonSchema,
      base_construction_thickness: Yup.number().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) =>
          schema
            .typeError(REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE)
            .min(0, THICKNESS_RANGE_MESSAGE)
            .max(100, WALL_THICKNESS_RANGE_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
      base_construction_lambda: Yup.number().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) =>
          schema
            .typeError(REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE)
            .min(0, CONSTRUCTION_THERMAL_RANGE_MESSAGE)
            .max(5, CONSTRUCTION_THERMAL_RANGE_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
      base_construction_material_name: Yup.string().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) => schema.typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
      ...insulationSchema,
    };

    const orientationSchema = {
      orientation: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .when(['delete'], {
          is: (envelopeDeleted: boolean) => !envelopeDeleted,
          then: (schema) =>
            schema
              .typeError(REQUIRED_MESSAGE)
              .required(REQUIRED_MESSAGE)
              .min(0, ORIENTATION_RANGE_MESSAGE)
              .max(360, ORIENTATION_RANGE_MESSAGE),
          otherwise: (schema) => schema.nullable(),
        }),
    };
    const pitchedRoofEnvelopeSchema = {
      ...otherEnvelopeSchema,
      inclination: Yup.number().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) => schema.typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
      ...orientationSchema,
    };

    const doorAndWindowCommonSchema = {
      ...orientationSchema,
      u_value: Yup.number().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) =>
          schema
            .typeError(REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE)
            .min(0, UVALUE_RANGE_MESSAGE)
            .max(5.9, UVALUE_RANGE_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
      insulation_material_name: Yup.string().when(['delete'], {
        is: (envelopeDeleted: boolean) => !envelopeDeleted,
        then: (schema) => schema.typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        otherwise: (schema) => schema.nullable(),
      }),
    };

    const EnvelopeDataSchema = Yup.object().shape({
      WINDOW: Yup.array(
        Yup.object().shape({
          ...envelopeCommonSchema,
          inclination: Yup.number().when(['delete'], {
            is: (envelopeDeleted: boolean) => !envelopeDeleted,
            then: (schema) => schema.typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
            otherwise: (schema) => schema.nullable(),
          }),
          insulation_lambda: Yup.number().when(['delete'], {
            is: (envelopeDeleted: boolean) => !envelopeDeleted,
            then: (schema) =>
              schema
                .typeError(REQUIRED_MESSAGE)
                .required(REQUIRED_MESSAGE)
                .min(0, GVALUE_RANGE_MESSAGE)
                .max(1, GVALUE_RANGE_MESSAGE),
            otherwise: (schema) => schema.nullable(),
          }),

          ...doorAndWindowCommonSchema,
        }),
      ),
      DOOR: Yup.array(
        Yup.object().shape({
          ...envelopeCommonSchema,
          ...doorAndWindowCommonSchema,
        }),
      ),
      PITCHED_ROOF: Yup.array(
        Yup.object().shape({
          ...pitchedRoofEnvelopeSchema,
        }),
      ),
      FLAT_ROOF: Yup.array(
        Yup.object().shape({
          ...otherEnvelopeSchema,
        }),
      ),
      TOP_FLOOR_CEILING: Yup.array(
        Yup.object().shape({
          ...otherEnvelopeSchema,
        }),
      ),
      WALL: Yup.array(
        Yup.object().shape({
          ...otherEnvelopeSchema,
          ...orientationSchema,
        }),
      ),
      FLOOR: Yup.array(
        Yup.object().shape({
          ...otherEnvelopeSchema,
        }),
      ),
      BASEMENT_CEILING: Yup.array(
        Yup.object().shape({
          ...otherEnvelopeSchema,
        }),
      ),
    });

    const methods = useForm<EnvelopeFormValues>({
      defaultValues,
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: shouldValidate ? yupResolver(EnvelopeDataSchema) : undefined,
    });

    const { control, reset } = methods;

    const { dirtyFields, errors } = useFormState({
      control,
    });

    const path = '/icons/envelopes/';
    const isDirty = areFieldArraysDirty(dirtyFields as unknown as EnvelopeFormValues, envelopeTypes);

    useEffect(() => {
      reset(defaultValues);
    }, [reset, defaultValues]);

    const errorCount = calculateTotalCount(errors, true);
    const editCount = calculateTotalEnvelopeEditCount(dirtyFields, methods.getValues());

    useEffect(() => {
      setShouldValidate(isDirty);
      setFormState({
        isDirty,
        errorCount,
        editCount,
      });
    }, [editCount, errorCount, isDirty, setFormState]);

    useImperativeHandle(ref, () => ({
      methods: methods,
    }));

    return (
      <>
        {isShown && (
          <Box width={'100%'}>
            <FormProvider methods={methods}>
              <Box mt={3}>
                {!buildingModel.action_planning_available && hasEditAccess && (
                  <Box mb={3}>
                    <InconsistentDataAlert />
                  </Box>
                )}
                {!envelopeUnits?.length && !hasEditAccess && <Unavailable title={t('General_NoDataAvailable')} />}
                {heatingDemandComponent}
                <TopEnvelopesSection title={t('General_TopBuildingSection')} icon={`${path}TOP.svg`} />
                <MiddleEnvelopesSection title={t('General_MiddleBuildingSection')} icon={`${path}MIDDLE.svg`} />
                <BottomEnvelopesSection title={t('General_BottomBuildingSection')} icon={`${path}BOTTOM.svg`} />
              </Box>
            </FormProvider>
          </Box>
        )}
      </>
    );
  },
);
export default BuildingEnvelope;

import { useQuery } from '@apollo/client';
import { Box, Divider, Stack, Tab, Tabs } from '@mui/material';
import {
  EsgAnalysisGetAllBuildingListQuery,
  EsgAnalysisGetFilteredBuildingsQuery,
  EsgAnalysisGetPortfolioQuery,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { t } from 'i18next';
import { useState } from 'react';
import { ESG_TAB_ICONS } from '../../../assets/icons';
import StickyBox from '../../../components/common/StickyBox';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import LoadingScreen from '../../../components/LoadingScreen';
import { GET_BUILDING_FILTER_VALUES } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { PATHS } from '../../../routes';
import EsgAnalysisBenchmark from '../Components/EsgAnalysisBenchmark';
import EsgAnalysisUnitSwitcher from '../Components/EsgAnalysisUnitSwitcher';
import EsgAnalysisViewSwitcher, { DisplayViewType } from '../Components/EsgAnalysisViewSwitcher';
import MetaInformation from '../Components/MetaInformation';
import EsgAnalysisFiltersSidebar from '../EsgAnalysisFilters/EsgAnalysisFiltersSidebar';
import { FloorAreaBySubBuildingClass, getPortfolioMetaInformation } from '../EsgAnalysisUtil';
import BuildingsSelector from '../NavigationSelectors/BuildingsSelector';
import PortfolioSelector from '../NavigationSelectors/PortfolioSelector';

export type ClimateRiskData = EsgAnalysisGetPortfolioQuery['getAnalyzedBuildings']['climateRisks'];
export type CurrentPageView = 'esg-portfolio' | 'esg-economics-units';
export type EsgAnalysisTab = 'demand' | 'consumption' | 'climate_risks';

type Props = {
  portfolios: EsgAnalysisPortfolioFragment[];
  currentTab: EsgAnalysisTab;
  setCurrentTab: (tab: EsgAnalysisTab) => void;
  setShowTotal: (showTotal: boolean) => void;
  setCurrentView: (view: DisplayViewType) => void;
  currentView: DisplayViewType;
  benchmarkId: number;
  setBenchmarkId: (id: number) => void;
  allBuildings: EsgAnalysisGetAllBuildingListQuery['building'];
  floorAreaByBuildingClass: FloorAreaBySubBuildingClass;
  filteredBuildings?: EsgAnalysisGetFilteredBuildingsQuery['building'];
};

export default function PortfolioAnalysisHeader({
  portfolios,
  currentTab,
  setCurrentTab,
  setShowTotal,
  setCurrentView,
  currentView,
  setBenchmarkId,
  benchmarkId,
  allBuildings,
  floorAreaByBuildingClass,
  filteredBuildings,
}: Props) {
  const { trackEvent } = usePosthogTracking();
  const currentYear = new Date().getFullYear();

  const { filters, resetAllFilters } = useEsgAnalysisFilters();
  const [openFilter, setOpenFilter] = useState(false);

  const { loading: filtersListLoading, data: filtersList } = useQuery(GET_BUILDING_FILTER_VALUES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      currentYear,
    },
  });

  if (filtersListLoading) {
    return <LoadingScreen />;
  }

  const tabs = [
    {
      label: t('General_Demand'),
      value: 'demand',

      icon: ESG_TAB_ICONS.DEMAND,
    },
    {
      label: t('General_ClimateRisks'),
      value: 'climate_risks',

      icon: ESG_TAB_ICONS.CLIMATE_RISKS,
    },
  ];

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolute') => {
    const doShowTotal = newDisplayBase === 'absolute';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  const links = [
    { name: t('General_ESGAnalysis'), href: PATHS.esgAnalysis.portfolio() },
    { name: t('General_Portfolio') },
  ];

  const totalBuildings = filteredBuildings?.length;

  const metaInfo = getPortfolioMetaInformation(floorAreaByBuildingClass, totalBuildings ?? 0);

  const portfolioBuildings = allBuildings?.filter((building) =>
    filters.portfolioIds?.includes(building.economic_unit.portfolio.id),
  );
  const isBuildingsFiltersActive = filters.buildingIds && filters.buildingIds.length > 0;
  const isPortfoliosFiltersActive = filters.portfolioIds && filters.portfolioIds.length > 0;

  return (
    <StickyBox>
      <Stack mb={5}>
        <HeaderBreadcrumbs
          heading={t('General_EsgAnalysisNavigationLabel')}
          links={links}
          sx={{ mb: 0 }}
          navigationSelector={
            <>
              {isBuildingsFiltersActive && (
                <BuildingsSelector
                  buildings={isBuildingsFiltersActive && isPortfoliosFiltersActive ? portfolioBuildings : allBuildings}
                />
              )}
              <PortfolioSelector
                portfolios={portfolios}
                filtersComponent={
                  <>
                    {currentTab === 'climate_risks' ? null : (
                      <EsgAnalysisFiltersSidebar
                        onResetAll={resetAllFilters}
                        isOpen={openFilter}
                        onOpen={() => setOpenFilter(true)}
                        onClose={() => setOpenFilter(false)}
                        portfolios={portfolios}
                        //@ts-ignore
                        filtersList={filtersList}
                      />
                    )}
                  </>
                }
              />
            </>
          }
        />

        <Box mt={-1}>{floorAreaByBuildingClass && <MetaInformation items={metaInfo} />}</Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => setCurrentTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{ mb: 1 }}
              icon={<Iconify icon={tab.icon} width={24} height={24} />}
            />
          ))}
        </Tabs>

        {currentTab !== 'climate_risks' && (
          <Stack direction={'row'} spacing={2}>
            {currentTab !== 'consumption' && (
              <>
                <EsgAnalysisBenchmark portfolios={portfolios} initialId={benchmarkId} setBenchmarkId={setBenchmarkId} />
                <EsgAnalysisViewSwitcher onViewChange={(view) => setCurrentView(view)} currentView={currentView} />
              </>
            )}
            <EsgAnalysisUnitSwitcher
              onDisplayBaseChange={onDisplayBaseChange}
              isConsumptionAnalysis={currentTab === 'consumption'}
            />
          </Stack>
        )}
      </Stack>

      <Divider />
    </StickyBox>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery, type useSubscription } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  DataCollectionBuildingModelFragment,
  EnergyConsumerMutation,
  EnergyDistributionMutation,
  EnergyStorageMutation,
  EnergySystemMutation,
  EnergySystemRouteMutation,
  EnvelopeMutation,
  data_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
} from '@predium/client-graphql';
import {
  async_job_status_type_enum,
  country_enum,
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
} from '@predium/enums';
import { cmToM, ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { FieldNamesMarkedBoolean, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import GeneralDataIcon from '../../assets/Icons/GeneralDataIcon';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import FullScreenLoader from '../../components/FullScreenLoader';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import NavigationModal from '../../components/NavigationModal/NavigationModal';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import { WarningModal } from '../../components/WarningModal';
import FormEditBar from '../../components/common/FormEditBar';
import StickyBox from '../../components/common/StickyBox';
import { APPLY_BUILDING_MODEL_MUTATIONS, DELETE_BUILDING } from '../../graphql/DataCollection.mutations';
import {
  BUILDING_PROCESSING_SUBSCRIPTION,
  GET_BUILDING,
  GET_BUILDING_MODEL_ACTIONS_STATUS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_PORTFOLIOS,
} from '../../graphql/DataCollection.queries';
import { ASYNC_JOB_SUBSCRIPTION_ENTITY_ORG } from '../../graphql/common.queries';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import useSessionData from '../../hooks/useSessionData';
import useTooltipConstants from '../../hooks/useTooltipConstants';
import { PATHS, SEARCH_PARAMS, useTypeSafeSearchParams } from '../../routes';
import SubBuildingAreas from '../../sections/DataCollection/Building/Areas/SubBuildingAreas';
import { AreaFormValues } from '../../sections/DataCollection/Building/Areas/types';
import BuildingActionMenu from '../../sections/DataCollection/Building/BuildingActionMenu';
import BuildingClimateRisk from '../../sections/DataCollection/Building/BuildingClimateRisk';
import BuildingEnvelope, { CUSTOM, EnvelopeFormValues } from '../../sections/DataCollection/Building/BuildingEnvelope';
import BuildingGeneral from '../../sections/DataCollection/Building/BuildingGeneral';
import BuildingTechnology, {
  EnergySystemFormValues,
  SystemRoute,
  consumerFields,
  distributionFields,
  energyRouteFields,
  storageFields,
} from '../../sections/DataCollection/Building/BuildingTechnology';
import { ActionPlanDeleteConfirmationDialog } from '../../sections/DataCollection/Building/Common/ActionDeleteConfirmationDialog';
import {
  AccessRightsRequiredAlert,
  ActionPlanDeleteAlert,
} from '../../sections/DataCollection/Building/Common/BuildingAlerts';
import { ConflictDialog } from '../../sections/DataCollection/Building/Common/ConflictDialog';
import DataSourceTypeChip from '../../sections/DataCollection/Building/Common/DataSourceTypeChip';
import { HeatingDemandConflictDialog } from '../../sections/DataCollection/Building/Common/HeatingDemandConflictDialog';
import useBuilding from '../../sections/DataCollection/Building/Context/useBuilding';
import SubBuildingDocuments from '../../sections/DataCollection/Building/Documents/SubBuildingDocuments';
import BuildingModelValuesSection, {
  BuildingModelValuesFormValues,
} from '../../sections/DataCollection/Building/Envelopes/EnvelopeSection/BuildingModelValuesSection';
import SubBuildingSubsidy from '../../sections/DataCollection/Building/General/SubBuildingSubsidy';
import SubBuildingConsumption from '../../sections/DataCollection/Building/SubBuildingConsumption';

export enum BuildingTabEnum {
  general = 'general',
  areas = 'areas',
  consumption = 'consumption',
  technology = 'technology',
  envelope = 'envelope',
  climateRisk = 'climateRisk',
  subsidies = 'subsidies',
  documents = 'documents',
}

type BuildingTab = {
  label: string | ReactNode;
  value: BuildingTabEnum;
  icon?: string;
  customIcon?: TabProps['icon'];
};

export type FormRef<T> = {
  methods: UseFormReturn<
    //@ts-ignore
    T,
    EnergySystemFormValues | EnvelopeFormValues | AreaFormValues | BuildingModelValuesFormValues
  >;
};

const SCALING_SNACKBAR_KEY = 'scaling_snackbar_key';

type FormState = {
  isDirty: boolean;
  errorCount: number;
  editCount: number;
};

const defaultFormState = {
  isDirty: false,
  errorCount: 0,
  editCount: 0,
};

type FormRefs = {
  technology: MutableRefObject<FormRef<EnergySystemFormValues> | null>;
  envelope: MutableRefObject<FormRef<EnvelopeFormValues> | null>;
  area: MutableRefObject<FormRef<AreaFormValues> | null>;
  buildingModelValues: MutableRefObject<FormRef<BuildingModelValuesFormValues> | null>;
};

export type SetFormState = React.Dispatch<React.SetStateAction<FormState>>;

export type NestedForm = {
  buildingModel: DataCollectionBuildingModelFragment;
  isShown: boolean;
  setFormState: SetFormState;
};

type Props = {
  useSubscription: typeof useSubscription;
};

export default function DataCollectionBuilding({ useSubscription }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id: buildingIdString } = useParams();
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.building, {
    tab: BuildingTabEnum.general,
  });

  const buildingId = parseInt(buildingIdString!);
  const { building, hasEditAccess, reloadCurrentBuilding } = useBuilding();
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const { org, serverSideFeatureFlags } = useSessionData();

  const formRefs: FormRefs = {
    technology: useRef(null),
    envelope: useRef(null),
    area: useRef(null),
    buildingModelValues: useRef(null),
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [technologyFormState, setTechnologyFormState] = useState<FormState>(defaultFormState);
  const [envelopeFormState, setEnvelopeFormState] = useState<FormState>(defaultFormState);
  const [areaFormState, setAreaFormState] = useState<FormState>(defaultFormState);
  const [buildingModelValuesFormState, setBuildingModelValuesFormState] = useState<FormState>(defaultFormState);

  const [openCancelEditDialog, setOpenCancelEditDialog] = useState(false);
  const [openConflictDialog, setOpenConflictDialog] = useState(false);
  const [openDeleteActionModal, setDeleteActionsModalOpen] = useState<boolean>(false);
  const [openAreaSaveDialog, setOpenAreaSaveDialog] = useState(false);

  const technologyFormMethods: UseFormReturn<EnergySystemFormValues> | undefined =
    formRefs?.technology?.current?.methods;

  const envelopeFormMethods: UseFormReturn<EnvelopeFormValues> | undefined = formRefs?.envelope?.current?.methods;

  const areaFormMethods: UseFormReturn<AreaFormValues> | undefined = formRefs?.area?.current?.methods;

  const buildingModelValuesFormMethods: UseFormReturn<BuildingModelValuesFormValues> | undefined =
    formRefs?.buildingModelValues?.current?.methods;

  // DELETE MUTATION
  const [deleteBuildingMutation] = useMutation(DELETE_BUILDING, {
    refetchQueries: [
      { query: GET_ECONOMIC_UNITS_WITH_PORTFOLIOS },
      {
        query: GET_ECONOMIC_UNITS_WITH_BUILDINGS,
      },
    ],
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_building_by_pk) {
        const normalizedId = cache.identify({
          id: buildingIdString,

          //@ts-ignore
          __typename: data.delete_building_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onError: () =>
      enqueueSnackbar(t('General_DeleteBuilding-error_one'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      enqueueSnackbar(t('General_DeleteBuilding-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      navigate(PATHS.dataCollection.buildings());
    },
  });

  useSubscription(ASYNC_JOB_SUBSCRIPTION_ENTITY_ORG, {
    skip:
      !org?.id ||
      !building?.sub_buildings[0].id ||
      building.sub_buildings.some((subBuilding) => subBuilding.subsidies_updated_at),
    variables: {
      title: 'UPDATE_SUBSIDIES',
      orgId: org?.id,
      entityId: building?.sub_buildings[0].id,
    },
    onData: async ({ data: subscriptionData }) => {
      //@ts-ignore
      if (!subscriptionData.data.async_job.length) {
        return;
      }

      //@ts-ignore
      const latestJob = subscriptionData.data.async_job[0];
      const status: async_job_status_type_enum = latestJob.status_id;
      /**
       * If the subsidies are not fetched yet, we can refetch after one minute.
       */
      if (
        status === async_job_status_type_enum.COMPLETED &&
        !building.sub_buildings.some((subBuilding) => subBuilding.subsidies_updated_at)
      ) {
        await reloadCurrentBuilding();
      }
    },
  });

  const {
    loading: actionStatusLoading,
    data: buildingModalMutabilityData,
    refetch: refetchBuildingModalMutabilityData,
  } = useQuery(GET_BUILDING_MODEL_ACTIONS_STATUS, {
    variables: {
      //@ts-ignore
      buildingModelId: building?.active_building_model?.id,
    },
    skip: !building.active_building_model?.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const buildingModelSubscription = useSubscription(BUILDING_PROCESSING_SUBSCRIPTION, {
    skip: !building?.active_building_model?.id,
    //@ts-ignore
    variables: { buildingModelId: building?.active_building_model?.id },
    onData: async ({ data }) => {
      //@ts-ignore
      const subscriptionData = data.data.building_model_by_pk;

      //@ts-ignore
      if (!subscriptionData.processing && !data.loading) {
        reloadCurrentBuilding();
        refetchBuildingModalMutabilityData();
      } else {
        return;
      }
    },
  });

  const isTechEnvelopeDirty =
    technologyFormState.isDirty || envelopeFormState.isDirty || buildingModelValuesFormState.isDirty;
  const isDirty = isTechEnvelopeDirty || areaFormState.isDirty;

  const errorSnackbar = () => {
    return enqueueSnackbar(t('DataCollection_TechnologyMutation-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
      key: SCALING_SNACKBAR_KEY,
    });
  };

  const successSnackbar = () => {
    return enqueueSnackbar(t('DataCollection_TechnologyMutation-success'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
      key: SCALING_SNACKBAR_KEY,
    });
  };

  const refetchQueries = [
    {
      query: GET_BUILDING_MODEL_ACTIONS_STATUS,
      variables: { buildingModelId: building?.active_building_model?.id },
    },
    { query: GET_BUILDING, variables: { buildingId: buildingId, year: new Date().getFullYear() } },
  ];

  const [applyBuildingModalMutations, { data: applyMutationResult, loading: applyMutationLoading }] = useMutation(
    APPLY_BUILDING_MODEL_MUTATIONS,
    {
      onError: () => errorSnackbar(),
      onCompleted: (data) => {
        if (data.applyBuildingModelMutations.scaling_successful) {
          successSnackbar();
          trackEvent('BUILDING_MODEL_MUTATED', {
            type: technologyFormState.isDirty ? 'systems' : 'envelopes',
          });
        } else {
          setOpenConflictDialog(true);
        }
      },
      refetchQueries: refetchQueries,
    },
  );

  const [saveBuildingModalMutations, { loading: saveMutationLoading }] = useMutation(APPLY_BUILDING_MODEL_MUTATIONS, {
    onError: () => errorSnackbar(),
    onCompleted: () => {
      successSnackbar();
    },
    refetchQueries: refetchQueries,
  });

  const isProcessing = buildingModelSubscription.data?.building_model_by_pk?.processing === true;

  const currentTab = searchParams.tab;

  useEffect(() => {
    if (isProcessing && currentTab !== BuildingTabEnum.general) {
      setSearchParams({ tab: BuildingTabEnum.general });
    }
  }, [currentTab, isProcessing, navigate, setSearchParams]);

  useEffect(() => {
    if (isTechEnvelopeDirty) {
      closeSnackbar(SCALING_SNACKBAR_KEY);
    }
  }, [closeSnackbar, isTechEnvelopeDirty]);

  if (actionStatusLoading) {
    return <LoadingScreen />;
  }

  const portfolioEmissionFactorType = building.economic_unit.portfolio?.emission_factor_type_id;

  const { active_building_model: buildingModel } = building;

  //@ts-ignore
  const { id: buildingModelId } = buildingModel;

  const { action_deletion_necessary, affected_action_plan_count, affected_users } =
    //@ts-ignore
    buildingModalMutabilityData.getBuildingModelMutationAvailability;

  function findOrCreateMutation(
    mutations:
      | EnergySystemRouteMutation[]
      | EnergyConsumerMutation[]
      | EnergyStorageMutation[]
      | EnergyDistributionMutation[],
    id: number,
  ) {
    let mutation = mutations.find(
      (m: EnergySystemRouteMutation | EnergyConsumerMutation | EnergyStorageMutation | EnergyDistributionMutation) =>
        m.id === id,
    );

    if (!mutation) {
      mutation = {
        id: id,
        ...(id < 0 ? { create: true } : {}),
      };
      mutations.push(mutation);
    }

    return mutation;
  }

  /**
   * user can edit single field, multiple fields
   * can also attach existing technology unit to route or newly created technology unit
   */
  const createRouteMutations = (
    energySystemRouteMutations: EnergySystemRouteMutation[],
    routeData: SystemRoute,
    key: keyof SystemRoute,
  ) => {
    const mutation: EnergySystemRouteMutation = findOrCreateMutation(
      energySystemRouteMutations,
      routeData.energyRouteId,
    );

    if (routeData.routeDeleted) {
      //if route is deleted, only need to send id and delete flag
      mutation.delete = routeData.routeDeleted;
      return;
    }

    switch (key) {
      case 'energySource':
        mutation.energy_source_type = routeData.energySource;
        break;
      case 'energyFinal':
        mutation.energy_final = routeData.energyFinal;
        break;
      case 'storageId':
        mutation.energy_storage_id = routeData.storageId;
        break;
      case 'consumerId':
        mutation.energy_consumer_id = routeData.consumerId;
        break;
      case 'distributionId':
        mutation.energy_distribution_id = routeData.distributionId;
        break;
      case 'emissionCertificateId':
        mutation.emission_certificate = {
          ...(routeData.emissionCertificateId > 0 ? { id: routeData.emissionCertificateId } : { delete: true }),
        };
        break;
      case 'primaryEnergyFactorEmissionCertificateId':
        mutation.primary_energy_factor_emission_certificate = {
          ...(routeData.primaryEnergyFactorEmissionCertificateId > 0
            ? { id: routeData.primaryEnergyFactorEmissionCertificateId }
            : { delete: true }),
        };
        break;
      default:
        const exhaustiveCheck = key;
        throw new Error(`Unhandled EnergySystemRouteMutation key ${key}: ${exhaustiveCheck}`);
    }
  };

  /**
   * user can edit single field, multiple fields or can also create new technology unit
   * backend needs to know what is edited
   */
  const createConsumerMutations = (
    energySystemConsumerMutations: EnergyConsumerMutation[],
    routeData: SystemRoute,
    key: keyof SystemRoute,
  ) => {
    const mutation: EnergyConsumerMutation = findOrCreateMutation(energySystemConsumerMutations, routeData.consumerId);

    if (
      (mutation.create &&
        (routeData.energySystemType === energy_system_type_enum.LIGHTING ||
          routeData.energySystemType === energy_system_type_enum.COOLING)) ||
      routeData.energySystemType === energy_system_type_enum.ELECTRICITY
    ) {
      mutation.efficiency = 1;
    }

    if (routeData.energySystemType === energy_system_type_enum.ELECTRICITY) {
      mutation.energy_consumer_type_id = energy_consumer_type_enum.ELECTRICITY;
      mutation.energy_consumer_technology_type_id = energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS;
      return;
    }

    switch (key) {
      case 'energyConsumerType':
        mutation.energy_consumer_type_id = routeData.energyConsumerType;
        break;
      case 'consumerTechnologyType':
        mutation.energy_consumer_technology_type_id = routeData.consumerTechnologyType;
        break;
      case 'expenditureFactor':
        mutation.efficiency = routeData.expenditureFactor;
        break;
      case 'energyConsumerConstructionYear':
        mutation.construction_year = routeData.energyConsumerConstructionYear;
        break;
      default:
        break;
    }
  };

  const createStorageMutations = (
    energySystemStorageMutations: EnergyStorageMutation[],
    routeData: SystemRoute,
    key: keyof SystemRoute,
  ) => {
    //fields will be shown as edited incase of attaching existing storage unit to route but backend doesn't those as it use id to make db relation
    const mutation: EnergyStorageMutation = findOrCreateMutation(energySystemStorageMutations, routeData.storageId);

    switch (key) {
      case 'energyStorageType':
        mutation.energy_storage_type_id = routeData.energyStorageType;
        break;
      case 'energyStorageHeatLoss':
        mutation.annual_heat_loss = routeData.energyStorageHeatLoss;
        break;
      case 'energyStorageConstructionYear':
        mutation.construction_year = routeData.energyStorageConstructionYear;
        break;
      case 'storageDeleted':
        mutation.delete = routeData.storageDeleted;
        break;
      default:
        break;
    }
  };

  const createDistributionMutations = (
    energySystemDistributionMutations: EnergyDistributionMutation[],
    routeData: SystemRoute,
    key: keyof SystemRoute,
  ) => {
    const mutation: EnergyDistributionMutation = findOrCreateMutation(
      energySystemDistributionMutations,
      routeData.distributionId,
    );

    switch (key) {
      case 'energyDistributionType':
        mutation.energy_distribution_type_id = routeData.energyDistributionType;
        break;
      case 'distributionHeatLoss':
        mutation.heat_distribution_loss = routeData.distributionHeatLoss;
        break;
      case 'transferHeatLoss':
        mutation.heat_transfer_loss = routeData.transferHeatLoss;
        break;
      case 'lastHydraulicBalancing':
        mutation.last_hydraulic_balancing = routeData.lastHydraulicBalancing;
        break;
      case 'lastBalancingSetToNever':
        mutation.last_balancing_set_to_never = routeData.lastBalancingSetToNever;
        break;
      case 'energyDistributionConstructionYear':
        mutation.construction_year = routeData.energyDistributionConstructionYear;
        break;
      case 'distributionDeleted':
        mutation.delete = routeData.distributionDeleted;
        break;
      default:
        break;
    }
  };

  const createSystemMutations = (
    data: EnergySystemFormValues,
    dirtyFields: FieldNamesMarkedBoolean<EnergySystemFormValues> | undefined,
  ) => {
    const energySystemConsumerMutations: EnergyConsumerMutation[] = [];
    const energySystemStorageMutations: EnergyStorageMutation[] = [];
    const energySystemDistributionMutations: EnergyDistributionMutation[] = [];
    const energySystemMutations: EnergySystemMutation[] = [];
    //only submit edited data
    if (dirtyFields && Object.keys(dirtyFields).length > 0) {
      for (const systemType in dirtyFields) {
        const systemData = data[systemType as keyof typeof dirtyFields];

        //@ts-ignore
        dirtyFields[systemType as keyof typeof dirtyFields].forEach((dirtySystem, systemIndex) => {
          const energySystemRouteMutations: EnergySystemRouteMutation[] = [];

          //@ts-ignore
          const system = systemData[systemIndex];
          if (!system) {
            return;
          }

          //@ts-ignore
          dirtySystem.routes.forEach((dirtyField, routeIndex) => {
            const routeData = system.routes[routeIndex];
            if (!routeData) {
              return;
            }

            Object.keys(dirtyField).forEach((key) => {
              if (energyRouteFields.includes(key)) {
                createRouteMutations(energySystemRouteMutations, routeData, key as keyof SystemRoute);
              }

              //ignore all other mutations if route is deleted
              if (routeData.routeDeleted) {
                return;
              }

              if (consumerFields.includes(key)) {
                createConsumerMutations(energySystemConsumerMutations, routeData, key as keyof SystemRoute);
              }

              if (storageFields.includes(key)) {
                createStorageMutations(energySystemStorageMutations, routeData, key as keyof SystemRoute);
              }

              if (distributionFields.includes(key)) {
                createDistributionMutations(energySystemDistributionMutations, routeData, key as keyof SystemRoute);
              }
            });
          });
          energySystemRouteMutations.length > 0 &&
            energySystemMutations.push({
              id: system.id,
              ...(system.id < 0 ? { create: true, energy_system_type_id: system.type } : {}),
              energy_system_route_mutations: energySystemRouteMutations,
            });
        });
      }
    }

    return {
      energySystemMutations,
      energySystemConsumerMutations,
      energySystemStorageMutations,
      energySystemDistributionMutations,
    };
  };

  /**
  Creates an array of envelope mutations based on the provided form data and dirty fields.
  The function takes data as the form data and dirtyFields as an object indicating which fields have changed.
  It iterates through each envelope type and its corresponding dirty fields to identify changes.
  For each changed field, it creates an object with the mutation details.
  Additional fields (specified in extraFields) are excluded from the mutation.
  u_value value is readonly field in the form and only editable for window and door.
  The resulting array includes mutations for changed envelopes and deleted envelopes.
  The function returns an array of envelope mutations
 */
  const createEnvelopeMutations = (
    data: EnvelopeFormValues,
    dirtyFields: FieldNamesMarkedBoolean<EnvelopeFormValues> | undefined,
  ) => {
    const envelopMutations: EnvelopeMutation[] = [];

    if (dirtyFields && Object.keys(dirtyFields).length > 0) {
      for (const envelope in dirtyFields) {
        //@ts-ignore
        dirtyFields[envelope as keyof typeof dirtyFields].forEach((dirtyField, i) => {
          const formData = data[envelope as keyof typeof dirtyFields]?.[i];

          if (formData) {
            if (formData?.delete) {
              envelopMutations.push({
                id: formData.envelope_id,
                delete: true,
              });
              return; //ignore everything else if envelope is deleted
            }

            const dirtyKeys = Object.keys(dirtyField).filter((key) => dirtyField[key as keyof typeof dirtyField]);

            //only used for readonly or frontend manipulations
            const extraFields = [
              'envelope_id',
              'data_source_type_id',
              'insulation_deleted',
              'has_insulation',
              'id',
              'delete',
            ];

            if (!(envelope === envelope_type_enum.DOOR || envelope === envelope_type_enum.WINDOW)) {
              extraFields.push('u_value');
              delete dirtyField.u_value;
            }

            if (Object.keys(dirtyField).length > 0) {
              envelopMutations.push({
                ...(formData?.create === true ? {} : { id: formData.envelope_id }),
                ...dirtyKeys
                  .filter((key) => !extraFields.includes(key))
                  .reduce((acc, key) => {
                    if (key === 'base_construction_thickness' || key === 'insulation_thickness') {
                      // @ts-ignore No time to try to understand this typing.
                      acc[key] = cmToM(formData[key]);
                    } else if (key === 'insulation_method') {
                      // @ts-ignore No time to try to understand this typing.
                      acc[key] = formData[key] === '' ? null : formData[key]; //default value of the select is empty string
                    } else {
                      // @ts-ignore No time to try to understand this typing.
                      acc[key] = formData[key];
                    }
                    if (key === 'insulation_material_name' && formData[key] === CUSTOM) {
                      // @ts-ignore No time to try to understand this typing.
                      acc['insulation_material_name'] = formData['insulation_material_custom'];
                    }
                    // @ts-ignore No time to try to understand this typing.
                    delete acc['insulation_material_custom'];

                    return acc;
                  }, {}),
              });
            }
          }
        });
      }
    }

    return envelopMutations;
  };

  const handleFormSubmit = async (saveInvalidState: boolean) => {
    let techData: EnergySystemFormValues = {};
    let envelopeData: EnvelopeFormValues = {};
    let buildingModelValuesData: BuildingModelValuesFormValues = {};

    await technologyFormMethods?.handleSubmit((data: EnergySystemFormValues) => {
      techData = data;
    })();

    await envelopeFormMethods?.handleSubmit((data: EnvelopeFormValues) => {
      envelopeData = data;
    })();

    await buildingModelValuesFormMethods?.handleSubmit((data: BuildingModelValuesFormValues) => {
      buildingModelValuesData = data;
    })();

    if (techData && envelopeData) {
      if (action_deletion_necessary && !openDeleteActionModal && !saveInvalidState) {
        setDeleteActionsModalOpen(true);
      } else {
        const {
          energySystemMutations,
          energySystemConsumerMutations,
          energySystemStorageMutations,
          energySystemDistributionMutations,
        } = createSystemMutations(techData, technologyFormMethods?.formState.dirtyFields);

        const mutations = {
          buildingModelId: buildingModelId,
          buildingModelMutation: buildingModelValuesData,
          envelopeMutations: createEnvelopeMutations(envelopeData, envelopeFormMethods?.formState.dirtyFields),
          energySystemMutations,
          energySystemConsumerMutations: energySystemConsumerMutations,
          energySystemStorageMutations: energySystemStorageMutations,
          energySystemDistributionMutations: energySystemDistributionMutations,
          saveInvalidState: saveInvalidState,
        };

        if (saveInvalidState) {
          saveBuildingModalMutations({
            variables: {
              ...mutations,
            },
          });
        } else {
          applyBuildingModalMutations({
            variables: {
              ...mutations,
            },
          });
        }
      }
    }
  };

  const buildingTabs = (
    [
      {
        label: t('DataCollection_BuildingTabs-general'),
        value: BuildingTabEnum.general,
        customIcon: <GeneralDataIcon />,
      },
      serverSideFeatureFlags.AREA_MANAGEMENT
        ? {
            label: t('DataCollection_BuildingTabs-areas'),
            value: BuildingTabEnum.areas,
            icon: 'mdi:texture-box',
          }
        : undefined,
      {
        label: (
          <>
            {t('DataCollection_BuildingTabs-technology')}
            {technologyFormState.editCount > 0 && (
              <Box ml={1}>
                <DataSourceTypeChip value={technologyFormState.editCount} type={data_source_type_enum.MANUAL} />
              </Box>
            )}
          </>
        ),
        value: BuildingTabEnum.technology,
        icon: 'mdi:clipboard-pulse-outline',
      },
      {
        label: (
          <>
            {t('DataCollection_BuildingTabs-envelope')}
            {(envelopeFormState.editCount > 0 || buildingModelValuesFormState.editCount > 0) && (
              <Box ml={1}>
                <DataSourceTypeChip
                  value={envelopeFormState.editCount + buildingModelValuesFormState.editCount}
                  type={data_source_type_enum.MANUAL}
                />
              </Box>
            )}
          </>
        ),
        value: BuildingTabEnum.envelope,
        icon: 'mdi:home-variant-outline',
      },
      {
        label: t('DataCollection_BuildingTabs-consumption'),
        value: BuildingTabEnum.consumption,
        icon: 'mdi:cog-outline',
      },
      {
        label: t('DataCollection_BuildingTabs-climateRisk'),
        value: BuildingTabEnum.climateRisk,
        icon: 'mdi:tsunami',
      },
      {
        label: t('DataCollection_BuildingTabs-subsidies'),
        value: BuildingTabEnum.subsidies,
        icon: 'mdi:percent-outline',
      },
      {
        label: t('DataCollection_BuildingTabs-documents'),
        value: BuildingTabEnum.documents,
        icon: 'mdi:file-outline',
      },
    ] satisfies (BuildingTab | undefined)[]
  ).filter((item) => typeof item !== 'undefined') as BuildingTab[];

  const deleteMessage =
    affected_action_plan_count > 0
      ? t('DataCollection_BuildingDeletionMessage-public', {
          count: affected_action_plan_count,
          affectedUser: affected_users[0].name,
        })
      : '';

  const showAlert = isTechEnvelopeDirty && action_deletion_necessary;

  const getCurrentTabComponent = () => {
    switch (currentTab) {
      case BuildingTabEnum.general:
        return <BuildingGeneral />;
      case BuildingTabEnum.consumption:
        return <SubBuildingConsumption />;
      case BuildingTabEnum.climateRisk:
        return <BuildingClimateRisk />;
      case BuildingTabEnum.subsidies:
        return <SubBuildingSubsidy />;
      case BuildingTabEnum.documents:
        return <SubBuildingDocuments />;
      case BuildingTabEnum.areas:
        if (!serverSideFeatureFlags.AREA_MANAGEMENT) {
          return null;
        }

        return (
          <SubBuildingAreas
            ref={formRefs.area}
            initialAreas={building.areas}
            setFormState={setAreaFormState}
            isSaveModalOpen={openAreaSaveDialog}
            setIsSaveModalOpen={setOpenAreaSaveDialog}
            // @ts-ignore
            isActionPlanningAvailable={buildingModel.action_planning_available}
          />
        );
    }
  };

  const envelopeErrorText =
    envelopeFormState.errorCount > 0
      ? t('DataCollection_EnvelopeFormErrorCount', { count: envelopeFormState.errorCount })
      : '';

  const technologyErrorText =
    technologyFormState.errorCount > 0
      ? t('DataCollection_TechnologyFormErrorCount', { count: technologyFormState.errorCount })
      : '';

  const errorCountText = `${technologyErrorText}${
    envelopeErrorText && technologyErrorText ? ', ' : ''
  }${envelopeErrorText}`;

  const address = `${building.address.street}, ${building.address.postal_code} ${building.address.city}`;

  const isChangingTabDisabled = (tab: BuildingTab) => {
    if (isProcessing && tab.value !== 'general') {
      return true;
    }

    // we block changing to any tab if there are unsaved changes in area
    if (areaFormState.isDirty && tab.value !== currentTab) {
      return true;
    }

    // we block changing to any tab if there are unsaved changes in technology or envelope
    if (isTechEnvelopeDirty && !['technology', 'envelope'].includes(tab.value)) {
      return true;
    }

    return false;
  };

  const heatingDemand = buildingModelValuesFormMethods?.getValues().heating_demand ?? buildingModel?.heating_demand;

  const isShowHeatingDemandValues = () => {
    if (building.address.country_id === country_enum.AT) {
      // Show heating demand to normal user if heating demand is already set (non-editable)
      // OR if the feature flag is enabled (only enabled for Predium users)
      if (heatingDemand || serverSideFeatureFlags.HEATING_DEMAND_EDITABLE) {
        return true;
      }
    }
    return false;
  };

  return (
    <Page title={t('PageName_Building')}>
      <Container maxWidth="lg">
        {hasEditAccess || (
          <Box sx={{ mb: 3 }}>
            <AccessRightsRequiredAlert />
          </Box>
        )}

        <HeaderBreadcrumbs
          heading={!isDirty && !isProcessing && address}
          links={[
            { name: t('General_DataCollection'), href: PATHS.dataCollection.buildings() },
            { name: t('PageName_BuildingOverview') },
          ]}
          sx={{ mb: !isDirty && !isProcessing ? 3 : 0 }}
        />

        {isProcessing && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: theme.palette.grey[200],
              width: '100%',
              px: 3,
              py: 2,
              mb: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="h5">{address}</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                {t('DataCollection_Building_ProcessingMessage')}
              </Typography>
              <CircularProgress
                size={25}
                sx={{
                  ml: 3,
                  color: (theme) => theme.palette.grey[500],
                }}
              />
            </Stack>
          </Stack>
        )}

        {isTechEnvelopeDirty && (
          <StickyBox>
            <FormEditBar
              onCancel={() => {
                setOpenCancelEditDialog(true);
              }}
              title={address}
              errorCountText={errorCountText}
              primaryButton={
                <>
                  <LoadingButton
                    variant="contained"
                    loading={applyMutationLoading}
                    onClick={() => handleFormSubmit(false)}
                  >
                    {t('General_Accept')}
                  </LoadingButton>
                </>
              }
            />
          </StickyBox>
        )}

        {areaFormState.isDirty && (
          <StickyBox>
            <FormEditBar
              title={address}
              onCancel={() => setOpenCancelEditDialog(true)}
              primaryButton={
                <Button
                  disabled={areaFormState.errorCount > 0}
                  variant="contained"
                  onClick={() => setOpenAreaSaveDialog(true)}
                >
                  {t('General_Save')}
                </Button>
              }
            />
          </StickyBox>
        )}

        <Grid container sx={{ mb: 5 }}>
          <Grid item xs={12} md={11} lg={11}>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_, value: BuildingTabEnum) => setSearchParams({ tab: value })}
            >
              {buildingTabs.map((tab) => (
                <Tab
                  disabled={isChangingTabDisabled(tab)}
                  disableRipple
                  key={tab.value}
                  label={tab.label}
                  //@ts-ignore
                  icon={tab.customIcon ?? <Iconify icon={tab.icon} width={24} height={24} />}
                  value={tab.value}
                  sx={{
                    '&.MuiButtonBase-root': {
                      mr: 3,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12} md={1} lg={1}>
            <Stack sx={{ p: 1 }} justifyContent={'end'} direction={'row'}>
              <Tooltip title={t('DataCollectionBuilding_TooltipShowESGAnalysis')}>
                <IconButton
                  onClick={() => {
                    navigate(PATHS.esgAnalysis.building({ id: building.id }));
                  }}
                >
                  <Iconify icon={'mdi:chart-bar-stacked'} width={20} height={20} />
                </IconButton>
              </Tooltip>

              {!isProcessing && (
                <BuildingActionMenu
                  economicUnitId={building.economic_unit.id}
                  currentAmountBuildingsInEu={building.economic_unit.associatedBuildingsAndDrafts.amount}
                  buildingId={building.id}
                  street={building.address.street}
                />
              )}
            </Stack>
          </Grid>
        </Grid>

        {showAlert && (
          <Box mt={1}>
            <ActionPlanDeleteAlert actionPlanCount={affected_action_plan_count} />
          </Box>
        )}

        {getCurrentTabComponent()}

        <BuildingTechnology
          //@ts-ignore
          buildingModel={buildingModel}
          //@ts-ignore
          portfolioEmissionFactorType={portfolioEmissionFactorType}
          ref={formRefs.technology}
          isShown={currentTab === BuildingTabEnum.technology}
          setFormState={setTechnologyFormState}
          address={building.address}
        />

        <BuildingEnvelope
          ref={formRefs.envelope}
          //@ts-ignore
          buildingModel={buildingModel}
          isShown={currentTab === BuildingTabEnum.envelope}
          setFormState={setEnvelopeFormState}
          heatingDemandComponent={
            isShowHeatingDemandValues() && (
              <BuildingModelValuesSection
                ref={formRefs.buildingModelValues}
                buildingModel={ensureDefined(buildingModel)}
                setFormState={setBuildingModelValuesFormState}
              />
            )
          }
        />

        <FullScreenLoader
          open={applyMutationLoading || saveMutationLoading}
          text={t('DataCollection_EnvelopeEditForm_LoaderText')}
          disableCloseAction
        />

        {heatingDemand ? (
          <HeatingDemandConflictDialog
            open={openConflictDialog}
            onClose={() => setOpenConflictDialog(false)}
            onClick={() => {
              setOpenConflictDialog(false);
              handleFormSubmit(true);
            }}
            heatingDemandSystem={applyMutationResult?.applyBuildingModelMutations.heating_demand_system ?? -1}
            heatingDemand={heatingDemand ?? -1}
            heatingDemandEnvelope={applyMutationResult?.applyBuildingModelMutations.heating_demand_envelope ?? -1}
          />
        ) : (
          <ConflictDialog
            open={openConflictDialog}
            onClose={() => setOpenConflictDialog(false)}
            onClick={() => {
              setOpenConflictDialog(false);
              handleFormSubmit(true);
            }}
            //@ts-ignore
            finalEnergy={applyMutationResult?.applyBuildingModelMutations.final_energy}
            //@ts-ignore
            finalEnergyScaled={applyMutationResult?.applyBuildingModelMutations.final_energy_scaled}
          />
        )}

        <ActionPlanDeleteConfirmationDialog
          actionPlanCount={affected_action_plan_count}
          setDeleteActionPlanModalOpen={setDeleteActionsModalOpen}
          open={openDeleteActionModal}
          onDeleteConfirm={() => {
            setDeleteActionsModalOpen(false);
            handleFormSubmit(false);
          }}
          deleteMessage={deleteMessage}
        />

        {
          //TODO check with Martin for messages on below both dialogs
        }
        <WarningModal
          title={t('General_CancelEditModalTitle')}
          open={openCancelEditDialog}
          description={t('General_CancelEditModalDescription')}
          onAcknowledge={() => {
            technologyFormMethods?.reset();
            envelopeFormMethods?.reset();
            buildingModelValuesFormMethods?.reset();

            if (areaFormMethods) {
              areaFormMethods.reset();
              setAreaFormState(defaultFormState);
            }

            setOpenCancelEditDialog(false);
          }}
          onClose={() => setOpenCancelEditDialog(false)}
          buttonText={t('General_Discard')}
          cancelText={t('General_Back')}
        />

        <NavigationModal
          title={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Title')}
          message={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Message')}
          primaryActionText={t('General_Save')}
          shouldBlock={() => isTechEnvelopeDirty}
          blockOnSearchParamChange={false}
          primaryActionCallback={async () => {
            const buildingForm = await handleFormSubmit(false);
            return Boolean(buildingForm);
          }}
        />

        <DeleteConfirmationModal
          open={deleteModalOpen}
          title={t('General_DeleteModalTitle-Building', { count: 1 })}
          description={
            <Trans
              i18nKey={'General_DeleteModalDescription-Building'}
              values={{
                name: building.address.street,
                count: 1,
                alongWithAssociatedData: '',
              }}
              components={{ bold: <strong /> }}
            />
          }
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          onDelete={() => {
            setDeleteModalOpen(false);
            deleteBuildingMutation({
              variables: {
                id: buildingId,
              },
            });
          }}
          deleteConfirmationText={t('General_DeleteModalConfirmation-Building', { count: 1 })}
          dataLossWarningText={t('General_DeleteModalWarning-Building')}
          tooltips={deleteTooltip}
        />
      </Container>
    </Page>
  );
}

import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import {
  crremNormalizedDistrictHeating,
  crremNormalizedElectricity,
  crremNormalizedGasOilOther,
  getRegionCode as getCRREMRegionCode,
  getRenewableDistrictHeatingEmissionFactor,
} from './crrem-normalized/crrem-normalized.helper';
import { CRREMEmissionFactorProvider } from './crrem.emission-factor-provider';
import { EmissionFactorProvider } from './emission-factor-provider.type';

export const CrremNormalizedEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    year,
    country,
    countryState,
    postalCode,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    const regionCode = getCRREMRegionCode(country, postalCode);
    const crremBaseEmissionFactor = CRREMEmissionFactorProvider.getEmissionFactor({
      energySourceType,
      year: 2020,
      country,
      countryState,
      postalCode,
    });
    switch (energySourceType) {
      case energy_source_type_enum.FUEL_OIL:
      case energy_source_type_enum.NATURAL_GAS:
      case energy_source_type_enum.LPG:
      case energy_source_type_enum.COAL:
      case energy_source_type_enum.LIGNITE:
      case energy_source_type_enum.WOODEN_PELLETS:
      case energy_source_type_enum.WOOD:
      case energy_source_type_enum.UNSPECIFIED:
      case energy_source_type_enum.BIO_GAS: {
        return crremBaseEmissionFactor * crremNormalizedGasOilOther(year, country, regionCode);
      }
      case energy_source_type_enum.ELECTRICITY_MIX:
      case energy_source_type_enum.ELECTRICITY_GREEN:
      case energy_source_type_enum.SOLAR: {
        return crremBaseEmissionFactor * crremNormalizedElectricity(year, regionCode, country);
      }
      case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
        return getRenewableDistrictHeatingEmissionFactor(country);
      case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL:
      case energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS:
      case energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL: {
        const crremBaseEmissionFactorElectricityCountry = CRREMEmissionFactorProvider.getEmissionFactor({
          energySourceType: energy_source_type_enum.ELECTRICITY_MIX,
          year,
          country,
          countryState,
          postalCode,
        });
        // Normalize the emission factor for the country by the UK Government GHG Conversion Factors for Company Reporting according to CRREM
        const crremBaseEmissionFactorElectricityUK = CRREMEmissionFactorProvider.getEmissionFactor({
          energySourceType: energy_source_type_enum.ELECTRICITY_MIX,
          year: 2020,
          country: 'UK' as unknown as country_enum,
          countryState,
          postalCode,
        });
        const crremNormalizedDistrictHeatingFactor =
          (crremBaseEmissionFactor * crremBaseEmissionFactorElectricityCountry) / crremBaseEmissionFactorElectricityUK;

        return crremNormalizedDistrictHeatingFactor * crremNormalizedDistrictHeating(year, regionCode, country);
      }
      default: {
        const exhaustiveCheck: never = energySourceType;
        throw new Error(`Unhandled energy_source_type_enum ${energySourceType}: ${exhaustiveCheck}`);
      }
    }
  },
};

import { country_enum, country_state_enum, energy_source_type_enum } from '@predium/enums';
import { getRenewableDistrictHeatingEmissionFactor } from './crrem-normalized/crrem-normalized.helper';
import { electricityEF } from './crrem-normalized/crrem-normalized.lookups';
import { EmissionFactorProvider } from './emission-factor-provider.type';

const CRREMGreenElectricity: Record<country_enum, number> = {
  [country_enum.DE]: 0.064,
  [country_enum.AT]: 0.032,
  [country_enum.PL]: 0.087,
};

export const CRREMEmissionFactorProvider: EmissionFactorProvider = {
  getEmissionFactor({
    energySourceType,
    country,
  }: {
    energySourceType: energy_source_type_enum;
    year: number;
    country: country_enum;
    countryState: country_state_enum;
    postalCode: string;
  }): number {
    const CRREM_lookup: Record<energy_source_type_enum, number> = {
      [energy_source_type_enum.FUEL_OIL]: 0.24677,
      [energy_source_type_enum.NATURAL_GAS]: 0.18316,
      [energy_source_type_enum.LPG]: 0.21419,
      [energy_source_type_enum.COAL]: 0.34473,
      [energy_source_type_enum.LIGNITE]: 0.34473,
      [energy_source_type_enum.WOODEN_PELLETS]: 0.01513,
      [energy_source_type_enum.WOOD]: 0.01513,
      [energy_source_type_enum.UNSPECIFIED]: 0.015333333,
      [energy_source_type_enum.ELECTRICITY_MIX]: electricityEF[country][2020],
      [energy_source_type_enum.ELECTRICITY_GREEN]: CRREMGreenElectricity[country],
      [energy_source_type_enum.SOLAR]: 0,
      [energy_source_type_enum.BIO_GAS]: 0.00022,

      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE]: getRenewableDistrictHeatingEmissionFactor(country),

      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS]: 0.20431,
      [energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE]: getRenewableDistrictHeatingEmissionFactor(country),
    };

    return CRREM_lookup[energySourceType];
  },
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from '@mui/material';
import { EsgAnalysisGetBuildingsPortfolioAndAddressFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import NavigationSelector from './NavigationMultiSelector';

export type AutoCompleteValue = {
  value: number | string;
  label: string;
};

type Props = {
  buildings: EsgAnalysisGetBuildingsPortfolioAndAddressFragment[] | undefined;
};

export default function BuildingsSelector({ buildings }: Props) {
  const { t } = useTranslation();
  const { filters, resetFilter, replaceFilter } = useEsgAnalysisFilters();

  if (!buildings) {
    return null;
  }

  /**
   * Creates each option for the building select
   */
  const mapToBuildingSelectOption = (
    building: EsgAnalysisGetBuildingsPortfolioAndAddressFragment,
  ): AutoCompleteValue => {
    return {
      value: building.id,
      label: building.address.street,
    };
  };

  const getBuildingsFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return buildings.filter((building) => ids.includes(building.id)).map(mapToBuildingSelectOption);
  };

  const onPortfolioSelected = (selectedBuildings: AutoCompleteValue[]) => {
    replaceFilter(
      EsgAnalysisFiltersKeys.buildingIds,
      selectedBuildings.map((selectedBuilding) => selectedBuilding.value as number),
    );
  };

  const buildingsOptions = buildings.map(mapToBuildingSelectOption);

  const filteredBuildings =
    filters.buildingIds && filters.buildingIds.length > 0 ? getBuildingsFromIds(filters.buildingIds) : [];

  const count = filteredBuildings.length;

  const selectorLabel =
    filteredBuildings.length > 1
      ? `${count} ${t('General_Building', {
          count: count,
        })}`
      : filteredBuildings[0]?.label ?? t('General_Building');

  return (
    <>
      <NavigationSelector
        onOptionSelected={onPortfolioSelected}
        options={buildingsOptions}
        filteredItems={filteredBuildings}
        selectorLabel={selectorLabel}
        noDataText={t('EsgAnalysisFilterBarBuildingFilter_NoBuildingsFound')}
        handleClear={() => {
          resetFilter(EsgAnalysisFiltersKeys.buildingIds);
        }}
        placeholder={t('General_SearchBuildingPlaceholder')}
      />
      <Typography variant="h4" sx={{ ml: 0.5 }}>
        {t('General_In')}
      </Typography>
    </>
  );
}

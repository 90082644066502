/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { EsgAnalysisGetAllBuildingFilterValuesQuery, EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { NAVBAR } from '../../../config';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import EsgAnalysisFilterValueLabel from './EsgAnalysisFilterValueLabel';
import { EsgAnalysisFiltersKeys, FindEsgAnalysisFilterValue, esgAnalysisFilters } from './EsgAnalysisFilters';

type FilterOptionsAsCheckboxesProps<Key extends EsgAnalysisFiltersKeys> = {
  title: React.ReactNode;
  type: Key;
  options: FindEsgAnalysisFilterValue<Key>[];
  selectedOptions: FindEsgAnalysisFilterValue<Key>[];
  getLabel?: (value: FindEsgAnalysisFilterValue<Key>) => React.ReactNode;
  onSelect?: (value: FindEsgAnalysisFilterValue<Key>) => void;
};

function FilterOptionsAsCheckboxes<Key extends EsgAnalysisFiltersKeys>({
  title,
  type,
  options,
  selectedOptions,
  getLabel,
  onSelect,
}: FilterOptionsAsCheckboxesProps<Key>) {
  const { setFilter } = useEsgAnalysisFilters();

  if (options.length < 2) {
    return null;
  }

  const handleSelect = (value: FindEsgAnalysisFilterValue<Key>) => {
    onSelect?.(value);
    setFilter(type, value);
  };

  return (
    <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
      <Stack spacing={1}>
        <Typography sx={{ ml: 1 }} variant="subtitle1">
          {title}
        </Typography>

        <Scrollbar sx={{ maxHeight: 300 }}>
          <FormGroup>
            {options.map((item, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={selectedOptions.includes(item)} onChange={() => handleSelect(item)} />}
                value={item}
                sx={{ ml: 0 }}
                label={getLabel?.(item) ?? <EsgAnalysisFilterValueLabel type={type} value={item} />}
              />
            ))}
          </FormGroup>
        </Scrollbar>
      </Stack>
    </Stack>
  );
}

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onResetAll: VoidFunction;
  onClose: VoidFunction;
  portfolios?: EsgAnalysisPortfolioFragment[];
  filtersList: EsgAnalysisGetAllBuildingFilterValuesQuery;
};

export default function EsgAnalysisFiltersSidebar({
  isOpen,
  onResetAll,
  onOpen,
  onClose,
  portfolios,
  filtersList,
}: Props) {
  const { t } = useTranslation();
  const { filters, resetFilter } = useEsgAnalysisFilters();

  /**
   * The options are coming from the database, so we need to map them to the correct keys.
   * We query the database to only show the filters that are available for the current data set.
   */

  //@ts-ignore
  const options: typeof filters = useMemo(
    () => ({
      subBuildingClasses: map(filtersList.sub_building_class, 'class_of_use_id'),
      energySourceTypes: map(filtersList.energy_system_consumer_route, 'energy_source_type_id'),
      efficiencyClasses: map(filtersList.energy_path_efficiency_class, 'efficiency_class_id'),
      taxBrackets: map(filtersList.energy_path_tax_bracket, 'tax_bracket_id'),
      euTaxonomyCompliances: map(filtersList.energy_path_eu_taxonomy_compliance, 'eu_taxonomy_compliance_id'),
      buildingStates: map(filtersList.building_state_id, 'building_state_id'),
      monumentProtections: map(filtersList.monument_protection, 'monument_protection'),
      heritageDistrict: map(filtersList.heritage_district, 'heritage_district'),
      milieuProtection: map(filtersList.milieu_protection, 'milieu_protection'),
      leasehold: map(filtersList.leasehold, 'leasehold'),
    }),
    [filtersList],
  );

  const getOptions = (key: EsgAnalysisFiltersKeys) => {
    const values = options[key];

    if (values) {
      return values;
    }

    throw new Error(`No options found for key: ${key}`);
  };

  const visibleFilters = esgAnalysisFilters.filter(({ showInSidebar }) => showInSidebar);

  const selectedPortfolioIds = filters.portfolioIds;
  const selectedEcoUnits = filters.economicUnitIds;

  const filterCount = sumBy(
    [selectedEcoUnits, selectedPortfolioIds, ...visibleFilters.map(({ key }) => filters[key])],
    'length',
  );

  return (
    <>
      <Stack direction={'row'} mr={1}>
        <Badge badgeContent={filterCount} color="error">
          <Button disableRipple color="inherit" endIcon={<Iconify icon={'ic:round-filter-list'} />} onClick={onOpen}>
            {t('General_Filter')}
          </Button>
        </Badge>
        {filterCount > 0 && (
          <Tooltip title={t('EsgAnalysisFilterSidebar_RemoveFilter')} placement="bottom">
            <IconButton sx={{ ml: 1, width: 36 }} color="error" size="small" onClick={onResetAll}>
              <Iconify icon={ICONS.TRASH} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: NAVBAR.FILTER_SIDEBAR_WIDTH },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 3 }}>
            {t('General_Filter')}
          </Typography>
          <IconButton onClick={onClose}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          {portfolios && portfolios.length > 1 && (
            <>
              <FilterOptionsAsCheckboxes
                title={t('General_Portfolio')}
                type={EsgAnalysisFiltersKeys.portfolioIds}
                options={portfolios.map(({ id }) => id)}
                //@ts-ignore
                selectedOptions={selectedPortfolioIds}
                getLabel={(id) => {
                  const portfolio = portfolios.find((portfolio) => portfolio.id === id);

                  //@ts-ignore
                  return portfolio.name;
                }}
                onSelect={() => resetFilter(EsgAnalysisFiltersKeys.buildingIds)}
              />
              <Divider sx={{ mt: 3 }} />
            </>
          )}

          {visibleFilters.map(({ key, title }) => (
            <FilterOptionsAsCheckboxes
              key={key}
              title={title}
              type={key}
              options={getOptions(key)}
              //@ts-ignore
              selectedOptions={filters[key]}
            />
          ))}
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="error"
            variant="outlined"
            onClick={onResetAll}
            startIcon={<Iconify icon={'ic:round-clear-all'} />}
          >
            {t('EsgAnalysisFilterSidebar_RemoveFilter')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Container } from '@mui/material';
import { SUPPORTED_YEARS } from '@predium/client-lookup';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import Unavailable from '../../components/Unavailable';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_ALL_BUILDINGS } from '../../graphql/EsgAnalysis.queries';
import usePermissions from '../../hooks/usePermissions';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import { PATHS } from '../../routes';
import EsgBuildingHeader from '../../sections/EsgAnalysis/Building/BuildingHeader';
import EsgBuildingBody from '../../sections/EsgAnalysis/Building/EsgBuildingBody';
import { ENTIRE_COMPANY } from '../../sections/EsgAnalysis/Components/EsgAnalysisBenchmark';
import { FloorAreaBySubBuildingClass } from '../../sections/EsgAnalysis/EsgAnalysisUtil';
import { EsgAnalysisTab } from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisHeader';

export default function EsgAnalysisBuilding() {
  const { t } = useTranslation();
  const { id: buildingId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPortfolioPermission } = usePermissions();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  const { trackEvent } = usePosthogTracking();

  const [currentTab, setCurrentTab] = useState<EsgAnalysisTab>('demand');
  const [floorAreaByBuildingClass, setFloorAreaByBuildingClass] = useState<FloorAreaBySubBuildingClass>([]);
  const [showTotal, setShowTotal] = useState(false);
  const [selectedReportingYear, setSelectedReportingYear] = useState(SUPPORTED_YEARS[SUPPORTED_YEARS.length - 1]);
  const [benchmarkId, setBenchmarkId] = useState<number | undefined>(ENTIRE_COMPANY);

  const {
    error: allBuildingsError,
    data: allBuildingsData,
    loading,
  } = useQuery(GET_ALL_BUILDINGS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const handleTabChange = (tab: EsgAnalysisTab) => {
    setCurrentTab(tab);

    switch (true) {
      case tab === 'demand' && benchmarkId === undefined:
        setBenchmarkId(ENTIRE_COMPANY);
        break;
      case tab === 'consumption':
        setBenchmarkId(undefined);
        break;
    }

    trackEvent('BUILDING_ESG_ANALYSIS_TAB_CHANGED', {
      display_base: tab,
    });
  };

  if (loading || !allBuildingsData) {
    return <LoadingScreen />;
  }

  const selectedBuilding =
    allBuildingsData.building.find((building) => building.id === Number(buildingId)) ?? allBuildingsData.building?.[0];

  if (allBuildingsError || !selectedBuilding) {
    return <Unavailable title={t('EsgAnalysisBuilding_BuildingsDoNotExists')} />;
  }

  const portfolios = uniqBy(
    allBuildingsData.building.map(({ economic_unit }) => ({
      id: economic_unit.portfolio.id,
      name: economic_unit.portfolio.name,
    })),
    'id',
  ).filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ));

  if (!buildingId) {
    return <Navigate to={PATHS.esgAnalysis.building({ id: selectedBuilding.id })} />;
  }

  return (
    <Page title={t('General_Building')}>
      <Container sx={{ mb: 1, pb: 30 }} maxWidth="lg">
        <EsgBuildingHeader
          allBuildingsData={allBuildingsData.building}
          currentTab={currentTab}
          setCurrentTab={handleTabChange}
          selectedBuilding={selectedBuilding}
          portfolios={portfolios}
          floorAreaByBuildingClass={floorAreaByBuildingClass}
          setShowTotal={setShowTotal}
          setSelectedReportingYear={setSelectedReportingYear}
          selectedReportingYear={selectedReportingYear}
          setBenchmarkId={setBenchmarkId}
          benchmarkId={benchmarkId}
        />
        {selectedBuilding && (
          <EsgBuildingBody
            currentTab={currentTab}
            selectedBuilding={selectedBuilding}
            setFloorAreaByBuildingClass={setFloorAreaByBuildingClass}
            portfolios={portfolios}
            selectedReportingYear={selectedReportingYear}
            showTotal={showTotal}
            benchmarkId={benchmarkId}
          />
        )}
      </Container>
    </Page>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Container } from '@mui/material';
import { getBuildingsWhere } from '@predium/graphql-where-client';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_ALL_BUILDINGS_LIST, GET_FILTERED_BUILDINGS } from '../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import usePermissions from '../../hooks/usePermissions';
import { ENTIRE_COMPANY } from '../../sections/EsgAnalysis/Components/EsgAnalysisBenchmark';
import { FloorAreaBySubBuildingClass } from '../../sections/EsgAnalysis/EsgAnalysisUtil';
import PortfolioAnalysisBody from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisBody';
import PortfolioAnalysisHeader, { EsgAnalysisTab } from '../../sections/EsgAnalysis/Portfolio/PortfolioAnalysisHeader';
import { getBuildingWhereFilters } from '../../utils/getWhereFilters';

export default function EsgAnalysisPortfolios() {
  const currentYear = new Date().getFullYear();

  const [floorAreaByBuildingClass, setFloorAreaByBuildingClass] = useState<FloorAreaBySubBuildingClass>([]);
  const [currentTab, setCurrentTab] = useState<EsgAnalysisTab>('demand');
  const [showTotal, setShowTotal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<'dashboard' | 'list'>('dashboard');
  const [benchmarkId, setBenchmarkId] = useState<number>(ENTIRE_COMPANY);

  const { filters } = useEsgAnalysisFilters();
  const { checkPortfolioPermission } = usePermissions();

  const { data: buildings, loading: buildingsLoading } = useQuery(GET_ALL_BUILDINGS_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: filteredBuildingsLoading,
    data: filteredBuildingsData,
    previousData: filteredBuildingsPreviousData,
  } = useQuery(GET_FILTERED_BUILDINGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      buildingWhere: getBuildingsWhere(getBuildingWhereFilters(filters)),
      currentYear,
    },
  });

  const filteredBuildings = filteredBuildingsData ?? filteredBuildingsPreviousData;

  if (buildingsLoading || (filteredBuildingsLoading && !filteredBuildings)) {
    return <LoadingScreen />;
  }

  const allBuildings = buildings?.building;

  const portfolios = uniqBy(
    allBuildings?.map(({ economic_unit }) => ({
      id: economic_unit.portfolio.id,
      name: economic_unit.portfolio.name,
    })),
    (economic_unit) => economic_unit.id,
  ).filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ));

  return (
    <Container sx={{ mb: 1, pb: 30 }} maxWidth="lg">
      {allBuildings && (
        <PortfolioAnalysisHeader
          portfolios={portfolios}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setShowTotal={setShowTotal}
          setCurrentView={setCurrentView}
          currentView={currentView}
          benchmarkId={benchmarkId}
          setBenchmarkId={setBenchmarkId}
          allBuildings={allBuildings}
          floorAreaByBuildingClass={floorAreaByBuildingClass}
          filteredBuildings={filteredBuildings?.building}
        />
      )}

      {filteredBuildings && (
        <PortfolioAnalysisBody
          buildings={filteredBuildings}
          portfolios={portfolios}
          currentPageView={'esg-portfolio'}
          setFloorAreaByBuildingClass={setFloorAreaByBuildingClass}
          currentTab={currentTab}
          showTotal={showTotal}
          benchmarkId={benchmarkId}
          currentView={currentView}
        />
      )}
    </Container>
  );
}

import { useMutation } from '@apollo/client';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { language_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CHANGE_USER_LANGUAGE } from '../../graphql/Users.mutations';
import useSessionData from '../../hooks/useSessionData';
import { useLanguage } from '../../provider/LanguageProvider';
import FullScreenLoader from '../FullScreenLoader';
import Iconify from '../Iconify';
import { SnackbarTimeouts } from '../NotistackProvider';

type LanguageSelectProps = {
  handleParentMenuClose: () => void;
};
function LanguageSelect({ handleParentMenuClose }: LanguageSelectProps) {
  const { t } = useTranslation();
  const { language: currentLanguage, setLanguage } = useLanguage();
  const { user } = useSessionData();

  const { enqueueSnackbar } = useSnackbar();

  const [changeServerLanguageForUserMutation, { loading: languageChangeLoading }] = useMutation(CHANGE_USER_LANGUAGE, {
    onCompleted: () =>
      enqueueSnackbar(t('LanguageSelect_ChangeLanguageSnackbar-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      }),
    onError: () =>
      enqueueSnackbar(t('LanguageSelect_ChangeLanguageSnackbar-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const handleLanguageChange = async (selectedLanguage: language_enum) => {
    handleParentMenuClose();
    // Make animation more fluid by adding some delay
    setLanguage(selectedLanguage);
    await changeServerLanguageForUserMutation({
      variables: {
        id: user!.id,
        language: selectedLanguage,
      },
    });
  };

  const getMenuDisplay = (language: language_enum) => {
    switch (language) {
      case language_enum.deDE:
        return t('General_German');
      case language_enum.enGB:
        return t('General_English');

      default:
        const exhaustiveCheck: never = language;
        throw new Error(`Unhandled language: ${exhaustiveCheck}`);
    }
  };

  return (
    <>
      <Stack direction="column" alignContent="flex-end" sx={{ minWidth: '200px' }}>
        <List sx={{ py: 0.5 }}>
          {Object.values(language_enum)
            .sort((language1, language2) => language1.localeCompare(language2))
            .map((language) => {
              return (
                <ListItem
                  key={language}
                  disablePadding
                  sx={{
                    color: 'text.primary',
                    fontSize: '14px',
                  }}
                >
                  <ListItemButton onClick={() => handleLanguageChange(language)} disabled={languageChangeLoading}>
                    <ListItemIcon>
                      <Iconify
                        icon="mdi:check"
                        color="action.active"
                        height={16}
                        width={16}
                        sx={{ visibility: currentLanguage !== language ? 'hidden' : 'visible' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={getMenuDisplay(language)} />
                  </ListItemButton>
                </ListItem>
              );
            })}

          {import.meta.env.VITE_SANDBOX === 'true' || import.meta.env.MODE === 'development' ? (
            <ListItem
              disablePadding
              sx={{
                color: 'text.primary',
                fontSize: '14px',
              }}
            >
              <ListItemButton
                onClick={() => {
                  setLanguage('DEBUG' as language_enum);
                  handleParentMenuClose();
                }}
              >
                <ListItemIcon>
                  <Iconify
                    icon="mdi:check"
                    color="action.active"
                    height={16}
                    width={16}
                    sx={{ visibility: currentLanguage !== ('DEBUG' as language_enum) ? 'hidden' : 'visible' }}
                  />
                </ListItemIcon>
                <ListItemText primary="DEBUG Mode" />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Stack>
      <FullScreenLoader open={languageChangeLoading} disableCloseAction text={t('General_InProgress')} />
    </>
  );
}

export default LanguageSelect;

import { country_enum, country_state_enum, emission_factor_type_enum, energy_source_type_enum } from '@predium/enums';
import { BahnersSchmitzEmissionFactorProvider } from './emission-factor-providers/bahners-schmitz.emission-factor-provider';
import { Co2TaxEmissionFactorProvider } from './emission-factor-providers/co2-tax.emission-factor-provider';
import { CRREMEmissionFactorProvider } from './emission-factor-providers/crrem.emission-factor-provider';
import { CrremNormalizedEmissionFactorProvider } from './emission-factor-providers/crrem.normalized-provider';
import { EmissionFactorProvider } from './emission-factor-providers/emission-factor-provider.type';
import { GegCRREMEmissionFactorProvider } from './emission-factor-providers/geg-crrem.emission-factor-provider';
import { GegEmissionFactorProvider } from './emission-factor-providers/geg.emission-factor-provider';
import { Gemis495EmissionFactorProvider } from './emission-factor-providers/gemis495.emission-factor-provider';
import { Gemis500EmissionFactorProvider } from './emission-factor-providers/gemis500.emission-factor-provider';
import { WuestEmissionFactorProvider } from './emission-factor-providers/wuest.emission-factor-provider';

const providerLibrary: Record<emission_factor_type_enum, EmissionFactorProvider> = {
  BAHNERS_SCHMITZ: BahnersSchmitzEmissionFactorProvider,
  CO2TAX: Co2TaxEmissionFactorProvider,
  CREEM: CRREMEmissionFactorProvider,
  GEG: GegEmissionFactorProvider,
  GEG_CRREM: GegCRREMEmissionFactorProvider,
  GEMIS_V495: Gemis495EmissionFactorProvider,
  GEMIS_V500: Gemis500EmissionFactorProvider,
  CREEM_V204_NORMALIZED: CrremNormalizedEmissionFactorProvider,
  WUEST_PARTNER: WuestEmissionFactorProvider,
};

// TODO (PRE-1596): Refactor this method: Currently this method dumps for each call all values in memory and calculates all kinds
//  of factors (unused) -> very inefficient, must be refactored soon!
export function getEmissionsFactor({
  energySourceType,
  emissionFactorType,
  year,
  country,
  countryState,
  postalCode,
}: {
  // country: country_enum,
  energySourceType: energy_source_type_enum;
  emissionFactorType: emission_factor_type_enum;
  year: number;
  country: country_enum;
  countryState: country_state_enum;
  postalCode: string;
}): number {
  if (year < 2020) {
    year = 2020;
  }
  if (year > 2050) {
    year = 2050;
  }

  if (emissionFactorType === emission_factor_type_enum.CO2TAX) {
    return providerLibrary[emissionFactorType].getEmissionFactor({
      energySourceType,
      year,
      country,
      countryState,
      postalCode,
    });
  }

  const changedEmissionFactorType =
    emissionFactorType === emission_factor_type_enum.CREEM ||
    emissionFactorType === emission_factor_type_enum.CREEM_V204_NORMALIZED
      ? emission_factor_type_enum.CREEM
      : emission_factor_type_enum.GEG;

  return providerLibrary[changedEmissionFactorType].getEmissionFactor({
    energySourceType,
    year,
    country,
    countryState,
    postalCode,
  });
}

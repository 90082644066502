/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  Card,
  Checkbox,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { EsgAnalysisGetBuildingsFragment } from '@predium/client-graphql';
import { EfficiencyClassOrder } from '@predium/client-lookup';
import { eu_taxonomy_compliance_enum } from '@predium/enums';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { fShortenNumber, getNufTypesOfUse } from '@predium/utils';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ICONS } from '../../assets/icons';
import EfficiencyClassLabel from '../../components/EfficiencyClassLabel';
import Iconify from '../../components/Iconify';
import OverflowText from '../../components/OverflowText';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { TableSelectedActions } from '../../components/table';
import TableMoreMenu from '../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import useTable, { applySortFilter } from '../../hooks/useTable';
import { PATHS } from '../../routes';
import EUTaxonomyTooltip, { calculateEUTaxonomyToBeSaved, euTaxonomyColors } from './Components/EUTaxonomyTooltip';
import EsgAnalysisBuildingListHead, { HeadLabel } from './EsgAnalysisBuildingListHead';
import EsgAnalysisBuildingListToolbar from './EsgAnalysisBuildingListToolbar';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';
import { getAbsoluteValueMultiplier, getUnit } from './EsgAnalysisUtil';
import EsgPdfExport from './EsgPdfExport';

// ----------------------------------------------------------------------

type Props = {
  filteredBuildings: EsgAnalysisGetBuildingsFragment[];
  showTotal: boolean;
};

export default function EsgAnalysisBuildingListTab({ filteredBuildings, showTotal }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filters, replaceFilter } = useEsgAnalysisFilters();
  const { buildingIds } = filters;

  //@ts-ignore
  const [selected, setSelected] = useState<number[]>(buildingIds);
  const [buildingToExportAsPdf, setBuildingToExportAsPdf] = useState<{
    id: number;
    street: string;
    city: string;
    postalCode: string;
  } | null>(null);

  useEffect(() => {
    //@ts-ignore
    setSelected(buildingIds);
  }, [buildingIds]);

  const handleAssetClick = (id: number) => {
    navigate(PATHS.esgAnalysis.building({ id }));
  };

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'street', defaultRowsPerPage: 25 });

  const filteredRows = applySortFilter({
    data: filteredBuildings,
    nameFilter: {
      fieldName: (item: EsgAnalysisGetBuildingsFragment) => item.address.street,
      filterValue: filterName,
    },
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        if (!item.active_building_model) {
          throw new Error('Data not available');
        }

        const { energy_primary, energy_final, energy_paths } = item.active_building_model;
        const multiplier = getAbsoluteValueMultiplier(showTotal, item);

        switch (orderBy) {
          case 'street':
            return item.address.street;
          case 'type_of_use_id':
            return item.areas[0].type_of_use_id;
          case 'co2_emissions':
            return (energy_paths[0].co2_emissions ?? -1) * multiplier;
          case 'co2_taxes':
            return (energy_paths[0].co2_costs ?? -1) * multiplier;
          case 'primary_energy':
            return (energy_primary ?? -1) * multiplier;
          case 'final_energy':
            return (energy_final ?? -1) * multiplier;
          case 'efficiency_class':
            //@ts-ignore
            return EfficiencyClassOrder.indexOf(energy_paths[0].efficiency_class_id);
          case 'eu_taxonomy':
            return energy_paths[0].eu_taxonomy_compliance_id;
          default:
            return item.address.street;
        }
      },
    },
  });

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex === -1) {
      setSelected([...selected, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelected([...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)]);
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = filteredRows.map((building) => building.id);
      setSelected(newSelectedIds);
    } else {
      setSelected([]);
    }
  };

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const tableHead: HeadLabel[] = [
    { id: 'street', label: t('General_Address'), minWidth: 240 },
    { id: 'typesOfUse', label: t('General_TypeOfUse'), minWidth: 140, sortingDisabled: true },
    {
      id: 'co2_emissions',
      label: t('General_CO2Intensity'),
      subtitle: getUnit('co2_emissions', showTotal),
      minWidth: 140,
    },
    { id: 'co2_taxes', label: t('General_CO2Taxes'), subtitle: getUnit('co2_taxes', showTotal), minWidth: 140 },
    {
      id: 'primary_energy',
      label: t('General_PrimaryEnergy'),
      subtitle: getUnit('primary_energy', showTotal),
      minWidth: 140,
    },
    {
      id: 'final_energy',
      label: t('General_FinalEnergy'),
      subtitle: getUnit('final_energy', showTotal),
      minWidth: 120,
    },
    { id: 'efficiency_class', label: t('General_EfficiencyClass'), minWidth: 160 },
    { id: 'eu_taxonomy', label: t('General_EUTaxonomyIndex'), minWidth: 180 },
    { id: 'actions', label: '', sortingDisabled: true },
  ];

  return (
    <Card sx={{ pt: 3 }}>
      <EsgAnalysisBuildingListToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          {/*@ts-ignore*/}
          {selected.length > 0 && !buildingIds.length && (
            <TableSelectedActions
              selectedText={t('General_BuildingSelected', { count: selected.length })}
              numSelected={selected.length}
              rowCount={filteredRows.length}
              onSelectAllRows={handleSelectAllClick}
              action={
                <>
                  {selected.length > 0 && (
                    <Button
                      variant="text"
                      onClick={() => {
                        replaceFilter(EsgAnalysisFiltersKeys.buildingIds, selected);
                      }}
                      endIcon={<Iconify icon={'material-symbols:add-box-outline'} />}
                    >
                      {t('EsgAnalysisBuildingListTab_LimitAnalysisTo')}
                    </Button>
                  )}
                </>
              }
            />
          )}
          <Table>
            <EsgAnalysisBuildingListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={filteredRows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              //@ts-ignore
              buildingIds={buildingIds}
              isNotFound={isNotFound}
            />
            <TableBody>
              {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                if (!row.active_building_model) {
                  throw new Error('Data not available');
                }
                // @ts-ignore
                const { id, address, areas, active_building_model } = row;
                const typesOfUse = getNufTypesOfUse(areas);
                const { efficiency_class_id, co2_emissions } = active_building_model.energy_paths[0];
                const isItemSelected = selected.indexOf(id) !== -1;

                const compliance = active_building_model.energy_paths[0].eu_taxonomy_compliance_id;

                const primaryEnergyToBeSaved = calculateEUTaxonomyToBeSaved(
                  //@ts-ignore
                  active_building_model.energy_primary,
                  compliance,
                  address.country_id,
                  // Fix ME in multiple NUF
                  typesOfUse[0],
                );

                const { energy_primary, energy_final, energy_paths } = active_building_model;
                let primaryEnergy, finalEnergy, co2Emissions, co2Costs;

                const multiplier = getAbsoluteValueMultiplier(showTotal, row);
                // @ts-ignore
                primaryEnergy = fShortenNumber(energy_primary * multiplier);
                // @ts-ignore
                finalEnergy = fShortenNumber(energy_final * multiplier);
                co2Emissions = fShortenNumber(co2_emissions * multiplier);
                co2Costs = fShortenNumber(energy_paths[0].co2_costs ? energy_paths[0].co2_costs * multiplier : 0);

                return (
                  <TableRow
                    hover
                    key={id}
                    role="checkbox"
                    //@ts-ignore
                    selected={!filters.buildingIds.length && isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/*@ts-ignore*/}
                    {!filters.buildingIds.length && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={() => handleCheckboxClick(id)} />
                      </TableCell>
                    )}
                    <TableCellWithSubtitle
                      onClick={() => handleAssetClick(row.id)}
                      title={
                        <OverflowText
                          text={address.street}
                          maxWidth={'200px'}
                          color={'text.primary'}
                          variant="body2"
                          TooltipProps={{
                            arrow: true,
                            placement: 'top-start',
                          }}
                        />
                      }
                      subtitle={
                        <OverflowText
                          text={`${address.postal_code} ${address.city}`}
                          maxWidth="250px"
                          color={'text.secondary'}
                          variant="body2"
                          TooltipProps={{
                            arrow: true,
                            placement: 'top-start',
                          }}
                        />
                      }
                    />
                    <TableCell onClick={() => handleAssetClick(row.id)}>
                      {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
                    </TableCell>
                    <TableCell onClick={() => handleAssetClick(row.id)}>{co2Emissions}</TableCell>
                    <TableCell onClick={() => handleAssetClick(row.id)}>{co2Costs}</TableCell>
                    <TableCell onClick={() => handleAssetClick(row.id)}>{primaryEnergy}</TableCell>
                    <TableCell onClick={() => handleAssetClick(row.id)}>{finalEnergy}</TableCell>
                    <TableCell onClick={() => handleAssetClick(row.id)} align="center">
                      {/*@ts-ignore */}
                      <EfficiencyClassLabel efficiencyClass={efficiency_class_id} />
                    </TableCell>
                    <TableCell>
                      {/*@ts-ignore */}
                      <EuTaxonomyTableCell compliance={compliance} toBeSaved={primaryEnergyToBeSaved} />
                    </TableCell>
                    <TableCell>
                      <TableMoreMenu
                        actions={
                          <MenuItem
                            onClick={() =>
                              setBuildingToExportAsPdf({
                                id,
                                city: address.city,
                                street: address.street,
                                postalCode: address.postal_code,
                              })
                            }
                          >
                            <Iconify icon={ICONS.PDF_EXPORT} />
                            {t('EsgAnalysis_ExportDialogOpen-ListMenuItem')}
                          </MenuItem>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

              {!filterName && filteredRows.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {t('EsgAnalysisBuildingListTab_NoBuildingsFound')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {isNotFound && <SearchNotFound searchQuery={filterName} />}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePaginationStandard
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      {/*@ts-ignore*/}
      <EsgPdfExport building={buildingToExportAsPdf} handleClose={() => setBuildingToExportAsPdf(null)} />
    </Card>
  );
}

type TableCellWithSubtitleProps = {
  title: ReactNode;
  subtitle: ReactNode;
  onClick: () => void;
};

function TableCellWithSubtitle({ title, subtitle, onClick }: TableCellWithSubtitleProps) {
  return (
    <TableCell onClick={onClick}>
      <Stack spacing={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {subtitle}
        </Typography>
      </Stack>
    </TableCell>
  );
}

function EuTaxonomyTableCell({
  compliance,
  toBeSaved,
}: {
  compliance: eu_taxonomy_compliance_enum;
  toBeSaved: number;
}) {
  const { t } = useTranslation();

  const text = {
    COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-compliant'),
    NON_COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-nonCompliant'),
    MEETS_DNSH_CRITERIA: t('ESGAnalysis_EUTaxonomyCompliance-partiallyCompliant'),
    UNKNOWN: t('ESGAnalysis_EUTaxonomyCompliance-unknown'),
  };

  return (
    <EUTaxonomyTooltip compliance={compliance} toBeSaved={toBeSaved}>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
        <Iconify
          icon={euTaxonomyColors.icon[compliance]}
          width={20}
          height={20}
          color={euTaxonomyColors.iconColor[compliance]}
        />
        <Typography
          color={euTaxonomyColors.complianceColor[compliance]}
          variant="subtitle2"
          sx={{ textDecoration: 'underline dotted', textUnderlinePosition: 'under' }}
        >
          {text[compliance]}
        </Typography>
      </Stack>
    </EUTaxonomyTooltip>
  );
}

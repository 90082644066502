/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Card, CircularProgress, Stack, TableCell, Tooltip } from '@mui/material';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getNufTypesOfUse } from '../../../../../../libs/utils/src/lib/area/area-helpers';
import { DelayedLoading } from '../../../components/Loading';
import { DataTable, DataTableColumns, QueryResultValue, QueryVariables } from '../../../components/data-table';
import { useDebounce } from '../../../components/data-table/debounce';
import { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { DataCollectionBuildingTabState } from '../../../contexts/DataCollectionFiltersContext';
import { GET_ALL_BUILDINGS } from '../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import { PATHS } from '../../../routes/paths';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';
import BuildingActionMenu from '../Building/BuildingActionMenu';
import BuildingsActionMenu from './BuildingActionsMenu';
import DataCollectionToolbar from './DataCollectionToolbar';
import { DataCollectionTabEnum } from './TabsEnums';

type Props = {
  setCurrentTab: (buildingTabData: DataCollectionBuildingTabState) => void;
};

export default function DataCollectionBuildingList({ setCurrentTab }: Props) {
  const { t } = useTranslation();

  const {
    filters: {
      dataCollectionBuildingFilters: { selectedPortfolio, selectedStatus, filterName },
    },
    setSelectedPortfolioBuilding,
    setSelectedStatusBuilding,
    setFilterNameBuilding,
  } = useDataCollectionFilters();

  const { loading: portfoliosLoading, data: portfoliosData } = useQuery(GET_PORTFOLIOS);

  const portfolioOptions = useMemo(() => {
    const portfoliosName = (portfoliosData?.portfolio ?? [])
      .map((portfolio) => portfolio.name)
      .filter((name): name is string => !isNil(name) && name !== '');

    return [ALL_PORTFOLIOS, ...portfoliosName];
  }, [portfoliosData]);

  const columns: DataTableColumns<typeof GET_ALL_BUILDINGS> = useMemo(
    () => [
      { type: 'select' },
      { id: 'address.street', label: t('General_Building'), minWidth: 300 },
      { id: 'address.city', label: t('General_City') },
      { id: 'address.postal_code', label: t('General_PostalCode') },
      {
        id: 'areas',
        label: t('General_TypeOfUse'),
        sortable: false,
        minWidth: 200,
        getValueLabel(value: QueryResultValue<typeof GET_ALL_BUILDINGS, 'areas'>) {
          const typesOfUse = getNufTypesOfUse(value);
          return typesOfUse.map((typeOfUse) => translateTypeOfUseEnum_dynamic(typeOfUse, t)).join(', ');
        },
      },
      {
        id: 'action_menu',
        label: '',
        sortable: false,
        renderCell: (data) => (
          <TableCell
            align="right"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}>
              {data.active_building_model?.processing ? (
                <Tooltip title={t('DataCollection_Building_ProcessingMessage')}>
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                    <CircularProgress
                      size={20}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <BuildingActionMenu
                  economicUnitId={data.economic_unit.id}
                  currentAmountBuildingsInEu={data.economic_unit.associatedBuildingsAndDrafts.amount}
                  buildingId={data.id}
                  street={data.address.street}
                />
              )}
            </Stack>
          </TableCell>
        ),
      },
    ],
    [t],
  );

  const debouncedFilterName = useDebounce(filterName, 500);

  const extraVariables: QueryVariables<typeof GET_ALL_BUILDINGS> = useMemo(
    () => ({
      where: {
        ...(debouncedFilterName && { address: { street: { _ilike: `%${debouncedFilterName}%` } } }),
        ...(selectedStatus !== 'all' && { building_state_id: { _eq: selectedStatus } }),
        ...(selectedPortfolio !== ALL_PORTFOLIOS && {
          economic_unit: { portfolio: { name: { _eq: selectedPortfolio } } },
        }),
      },
    }),
    [debouncedFilterName, selectedStatus, selectedPortfolio],
  );

  if (!portfoliosData && portfoliosLoading) {
    return <DelayedLoading />;
  }

  return (
    <Card>
      <DataCollectionToolbar
        filterName={filterName}
        onFilterName={setFilterNameBuilding}
        handlePortfolioChange={setSelectedPortfolioBuilding}
        portfolioOptions={portfolioOptions}
        selectedPortfolio={selectedPortfolio}
        selectedStatus={selectedStatus}
        handleStatusChange={setSelectedStatusBuilding}
        setCurrentTab={() => setCurrentTab({ value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS })}
      />
      <DataTable
        columns={columns}
        query={GET_ALL_BUILDINGS}
        extraVariables={extraVariables}
        initials={{ sortBy: 'address.street', rowsPerPage: 25 }}
        slotProps={{
          tableRow: (row) => ({
            background: hasRecentlyChanged(row.created_at) ? 'success' : 'default',
            to: PATHS.dataCollection.building({ id: row.id }),
          }),
        }}
        labels={{ selectedLabel: (count) => t('General_BuildingSelected', { count }) }}
        actions={({ selectedIds, onActionCompleted }) => (
          <BuildingsActionMenu buildingsId={selectedIds} onActionCompleted={onActionCompleted} />
        )}
        searchKeyword={filterName}
      />
    </Card>
  );
}

import { Stack, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { fPercent } from '@predium/utils';
import Iconify from '../Iconify';

type ChangeType = 'positive' | 'neutral' | 'negative';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

type Props = {
  percent: number;
};

export function classifyPercentage(percentageStr: number): ChangeType {
  if (percentageStr > 0) {
    return 'positive';
  } else if (percentageStr === 0) {
    return 'neutral';
  } else {
    return 'negative';
  }
}

export default function WidgetSummary({ percent }: Props) {
  const theme = useTheme();
  const roundedPercent = percent > 0 ? Math.floor(percent * 10) / 10 : Math.ceil(percent * 10) / 10;
  const percentageSignal = classifyPercentage(roundedPercent);

  let color;

  if (percentageSignal === 'positive') {
    color = theme.palette.error.main;
  } else if (percentageSignal === 'negative') {
    color = theme.palette.success.main;
  } else {
    color = theme.palette.text.primary;
  }
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 1 }}>
      <IconWrapperStyle
        sx={{
          color: color,
          bgcolor: alpha(color, 0.16),
        }}
      >
        <Iconify
          width={16}
          height={16}
          icon={
            percentageSignal === 'positive'
              ? 'eva:trending-up-fill'
              : percentageSignal === 'negative'
              ? 'eva:trending-down-fill'
              : 'eva:activity-outline'
          }
        />
      </IconWrapperStyle>
      <Typography component="span" variant="subtitle2">
        {percentageSignal === 'positive' && '+'}
        {fPercent(
          percent,
          percentageSignal === 'neutral' ? true : false,
          percentageSignal === 'neutral' ? 'never' : 'auto',
        )}
      </Typography>
    </Stack>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  ListItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';

export type AutoCompleteValue = {
  value: number | string;
  label: string;
};

export const CustomPaper = styled(Paper)(() => ({
  position: 'relative',
  height: 420,
  overflow: 'hidden !important',
  mb: 6,
  boxShadow: 'none !important',
}));

type Props = {
  options: AutoCompleteValue[];
  filteredItems: AutoCompleteValue[];
  onOptionSelected: (selectedItems: AutoCompleteValue[]) => void;
  selectorLabel?: string;
  noDataText: string;
  filtersComponent?: ReactNode;
  handleClear: () => void;
  placeholder?: string;
};

export default function NavigationMultiSelector({
  options,
  filteredItems,
  onOptionSelected,
  selectorLabel = '',
  noDataText,
  filtersComponent,
  handleClear,
  placeholder = '',
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showAutocomplete, setShowAutocomplete] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const handleTypographyClick = () => {
    setShowAutocomplete(!showAutocomplete);
  };

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: AutoCompleteValue[]) => {
    onOptionSelected(newValue);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
        setShowAutocomplete(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autocompleteRef]);

  return (
    <Box ref={autocompleteRef} position={'relative'} ml={0.5}>
      <Stack direction="row" alignItems={'center'}>
        <Button
          onClick={handleTypographyClick}
          sx={{
            width: 'fit-content',
            background: showAutocomplete ? theme.palette.action.selected : '',
            color: 'text.secondary',
          }}
        >
          <Typography variant="h4">{selectorLabel}</Typography>
          <Iconify icon={ICONS.CHEVRON_DOWN} width={24} height={24} sx={{ color: 'text.secondary' }} />
        </Button>
        {filtersComponent && <Box>{filtersComponent}</Box>}
      </Stack>
      {showAutocomplete && (
        <Box
          mt={0.5}
          sx={{
            '.MuiAutocomplete-popper': {
              zIndex: 2,
              background: theme.palette.common.white,
              pt: 1,
            },
            mt: 1,
            height: 480,
            width: 480,
            position: 'absolute',
            border: '1px solid',
            borderColor: 'divider',
            background: theme.palette.common.white,
            zIndex: 2,
            borderRadius: '8px',
            p: 1,
            boxShadow: theme.shadows[3],
            '.MuiAutocomplete-listbox': {
              maxHeight: '90%',
              scrollbarWidth: 'thin',
              pl: 0,
            },
            '.MuiAutocomplete-option': {
              px: 0.5,
            },
          }}
        >
          <Autocomplete
            multiple
            open={showAutocomplete}
            options={options}
            getOptionLabel={(option: AutoCompleteValue) => option.label}
            onChange={handleChange}
            value={filteredItems}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'text.secondary',
                    '&.Mui-focused fieldset': {
                      border: '1px solid',
                      borderColor: 'divider',
                    },
                  },
                }}
                size={'small'}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                  startAdornment: (
                    <Iconify icon={ICONS.MAGNIFY} width={24} height={24} sx={{ color: 'text.secondary' }} />
                  ),
                }}
                placeholder={placeholder}
              />
            )}
            noOptionsText={noDataText}
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={() => null}
            disablePortal
            openOnFocus
            sx={{
              '.MuiFormControl-root': {
                background: theme.palette.common.white,
                zIndex: 1,
              },
            }}
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} key={option.value} sx={{ pl: 1 }}>
                <Checkbox checked={selected} size={'small'} />
                <Typography>{option.label}</Typography>
              </ListItem>
            )}
            PaperComponent={(props) => (
              <CustomPaper {...props}>
                {props.children}
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  p={1}
                  sx={{
                    background: theme.palette.common.white,
                    height: 48,
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setShowAutocomplete(false);
                      handleClear();
                    }}
                    sx={{
                      position: 'absolute',
                      bottom: (theme) => theme.spacing(1),
                      right: (theme) => theme.spacing(2),
                    }}
                    disabled={filteredItems.length === 0}
                  >
                    {t('General_Clear')}
                  </Button>
                </Stack>
              </CustomPaper>
            )}
          />
        </Box>
      )}
    </Box>
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Container, Grid, Stack, Tooltip, useTheme } from '@mui/material';
import {
  EsgAnalysisGetAllBuildingsQuery,
  EsgAnalysisGetBuildingsForSingleBuildingFragment,
} from '@predium/client-graphql';
import { ensureDefined, getBuildingIsMixed } from '@predium/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets/icons';
import StickyBox from '../../../components/common/StickyBox';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import InlineUser from '../../../components/InlineUser';
import Page from '../../../components/Page';
import Unavailable, { UnavailablePageForMissingPortfolios } from '../../../components/Unavailable';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { PATHS } from '../../../routes';
import { DataCollectionTabActionsEnum } from '../../DataCollection/Buildings/TabsEnums';
import MetaInformation from '../Components/MetaInformation';
import {
  FloorAreaBySubBuildingClass,
  getMixUseBuildingMetadata,
  getSingleUseBuildingMetadata,
} from '../EsgAnalysisUtil';
import EsgPdfExport from '../EsgPdfExport';
import BuildingSelector from '../NavigationSelectors/BuildingSelector';
import { AutoCompleteValue } from '../NavigationSelectors/NavigationMultiSelector';
import { EsgAnalysisTab } from '../Portfolio/PortfolioAnalysisHeader';
import EsgAnalysisBuildingTabs from './EsgAnalysisBuildingTabs';

type Props = {
  allBuildingsData: EsgAnalysisGetAllBuildingsQuery['building'];
  currentTab: EsgAnalysisTab;
  setCurrentTab: (tab: EsgAnalysisTab) => void;
  selectedBuilding: EsgAnalysisGetBuildingsForSingleBuildingFragment | null;
  portfolios: { id: number; name?: string | null }[];
  floorAreaByBuildingClass: FloorAreaBySubBuildingClass;
  setShowTotal: (showTotal: boolean) => void;
  setSelectedReportingYear: (year: number) => void;
  selectedReportingYear: number;
  benchmarkId: number | undefined;
  setBenchmarkId: (benchmarkId: number) => void;
};

export default function EsgBuildingHeader({
  selectedBuilding,
  allBuildingsData,
  currentTab,
  setCurrentTab,
  floorAreaByBuildingClass,
  portfolios,
  setShowTotal,
  setSelectedReportingYear,
  selectedReportingYear,
  benchmarkId,
  setBenchmarkId,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const [buildingToExportAsPdf, setBuildingToExportAsPdf] = useState<{
    id: number;
    street: string;
    city: string;
    postalCode: string;
  } | null>(null);

  const navigate = useNavigate();

  const onBuildingSelected = (building: AutoCompleteValue) => {
    const buildingSelected = ensureDefined(allBuildingsData.find((a) => a.id === building.value));
    navigate(PATHS.esgAnalysis.building({ id: buildingSelected.id }));

    trackEvent('BUILDING_SELECTED', {
      building_id: buildingSelected.id,
    });
  };

  const links = [
    { name: t('General_ESGAnalysis'), href: PATHS.esgAnalysis.portfolio() },
    { name: t('General_Building') },
  ];

  if (!selectedBuilding) {
    return (
      <UnavailablePageForMissingPortfolios
        title={t('General_Building')}
        heading={t('PageName_ESGAnalysisBuildingView')}
      >
        <Page title={t('General_Building')}>
          <Container sx={{ mb: 3 }} maxWidth="lg">
            <Grid container>
              <Grid item xs={6}>
                <HeaderBreadcrumbs heading={t('PageName_ESGAnalysisBuildingView')} links={links} />
              </Grid>
              <Unavailable
                title={t('General_BuildingsUnavailable-title')}
                subTitle={t('General_BuildingsUnavailable-subtitle')}
                onClick={() =>
                  navigate(
                    PATHS.dataCollection.buildings(null, {
                      action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
                    }),
                  )
                }
              />
            </Grid>
          </Container>
        </Page>
      </UnavailablePageForMissingPortfolios>
    );
  }

  const buildingDataMissing = selectedBuilding.address.mapbox_building_ids?.length < 1;

  const onePagerButton = (
    <Button
      variant="contained"
      disabled={buildingDataMissing}
      onClick={() =>
        setBuildingToExportAsPdf({
          id: selectedBuilding.id,
          street: selectedBuilding.address.street,
          city: selectedBuilding.address.city,
          postalCode: selectedBuilding.address.postal_code,
        })
      }
      startIcon={<Iconify icon={ICONS.PDF_EXPORT} width={20} height={20} />}
    >
      {t('EsgAnalysis_ExportDialogOpen-ButtonContained')}
    </Button>
  );
  const isMixedUseBuilding = getBuildingIsMixed(selectedBuilding.areas);

  const metaInfo = isMixedUseBuilding
    ? getMixUseBuildingMetadata(selectedBuilding, floorAreaByBuildingClass)
    : getSingleUseBuildingMetadata(selectedBuilding);

  return (
    <StickyBox>
      <Grid container mb={5} alignItems={'center'}>
        <Grid item xs={9}>
          <HeaderBreadcrumbs
            heading={t('General_EsgAnalysisNavigationLabel')}
            links={links}
            sx={{ mb: 0 }}
            navigationSelector={
              <BuildingSelector
                buildings={allBuildingsData ?? []}
                onBuildingSelected={onBuildingSelected}
                selectedBuilding={selectedBuilding}
              />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Stack direction={'row'} justifyContent="end" mt={3}>
            {selectedBuilding.responsible_user ? (
              <InlineUser
                firstName={selectedBuilding.responsible_user.first_name}
                lastName={selectedBuilding.responsible_user.last_name}
                sx={{ pr: 2 }}
                showNameAsTooltip
                size={30}
              />
            ) : null}

            <Tooltip title={t('General_OpenInDataCollection')}>
              <Button
                size="medium"
                variant="outlined"
                sx={{
                  background: theme.palette.common.white,
                  minWidth: 'unset',
                  mr: 2,
                  px: 1.5,
                }}
                onClick={() => {
                  navigate(PATHS.dataCollection.building({ id: selectedBuilding.id }));
                }}
                disabled={!selectedBuilding}
              >
                <Iconify icon={ICONS.DATABASE_OUTLINE} width={20} height={20} sx={{ color: 'text.secondary' }} />
              </Button>
            </Tooltip>

            {buildingDataMissing ? (
              <Tooltip title={t('General_BuildingInformationNotAvailableMessage')}>
                <Box component="span">{onePagerButton}</Box>
              </Tooltip>
            ) : (
              onePagerButton
            )}
            {buildingToExportAsPdf && (
              <EsgPdfExport building={buildingToExportAsPdf} handleClose={() => setBuildingToExportAsPdf(null)} />
            )}
          </Stack>
        </Grid>
        {floorAreaByBuildingClass.length > 0 && (
          <Box mt={-1}>
            <MetaInformation items={metaInfo} />
          </Box>
        )}
      </Grid>
      <EsgAnalysisBuildingTabs
        selectedBuilding={selectedBuilding}
        portfolios={portfolios}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setShowTotal={setShowTotal}
        selectedReportingYear={selectedReportingYear}
        setSelectedReportingYear={setSelectedReportingYear}
        benchmarkId={benchmarkId}
        setBenchmarkId={setBenchmarkId}
      />
    </StickyBox>
  );
}

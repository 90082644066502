import { graphql } from '@predium/client-graphql';

graphql(`
  fragment ReportGetReportByPkReportTemplateSections on report_template_section {
    __typename
    id
    name
    order
    parent_section_id
    target
  }
`);

graphql(`
  fragment ReportGetReportByPkReportTemplate on report_template {
    single_sub_building
    title
    excel_template_path
    report_sections(order_by: { order: asc }) {
      ...ReportGetReportByPkReportTemplateSections
    }
  }
`);

graphql(`
  fragment ReportGetReportByPkResponsibleUser on user {
    __typename
    id
    last_name
    first_name
  }
`);

graphql(`
  fragment ReportGetReportByPkSubBuilding on sub_building {
    __typename
    id
    building {
      address {
        street
      }
      economic_unit {
        portfolio {
          id
          name
        }
      }
    }
  }
`);

graphql(`
  fragment ReportGetReportByPk on report {
    __typename
    id
    end_date
    start_date
    due_date
    created_at
    completed
    report_template {
      ...ReportGetReportByPkReportTemplate
    }
    responsible_user {
      ...ReportGetReportByPkResponsibleUser
    }
    report_sub_buildings {
      sub_building {
        ...ReportGetReportByPkSubBuilding
      }
    }
    report_sub_buildings_aggregate {
      aggregate {
        count
      }
    }
    # filters all autofill-able answers - this is the current way to detect autofill questions from a template.
    report_answers(
      where: {
        _and: [
          { state: { _eq: UNANSWERED } }
          {
            _or: [
              { autofill: { _is_null: false } }
              { report_answer_autofill_options: { report_template_question_option: { value: { _is_null: false } } } }
            ]
          }
        ]
      }
    ) {
      __typename
      id
      report_template_question {
        evidence_required
      }
    }
  }
`);

graphql(`
  fragment ReportSectionGetRootQuestionsAndAnswersAnswers on report_answer {
    id
    __typename
    report_question_id
    value
    state
    autofill
    report_template_question {
      __typename
      id
      question
      type
      # A 3-state boolean that idicates whether the evidence is required, optional or not applicable at all.
      evidence_required
    }
    report_answer_autofill_options {
      report_template_question_option {
        id
        __typename
        value
      }
    }
    report_answer_chosen_options {
      updated_at
      report_template_question_option {
        id
        value
      }
    }
  }
`);

graphql(`
  fragment CreateReportModalGetAllTemplatesTemplate on ReportTemplateOutput {
    __typename
    id
    description
    org_id
    title
    single_sub_building
    version
  }
`);

graphql(`
  fragment ReportGetReportsSubBuildings on report_sub_building {
    sub_building {
      __typename
      id
      building {
        economic_unit {
          portfolio {
            id
            name
          }
        }
        address {
          street
          city
          postal_code
        }
      }
    }
  }
`);

export const GET_REPORT = graphql(`
  query ReportGetReport($id: Int!) {
    report_by_pk(id: $id) {
      ...ReportGetReportByPk
    }
  }
`);

export const GET_REPORTS = graphql(`
  query ReportingReports {
    report {
      __typename
      id
      report_template {
        __typename
        id
        title
        excel_template_path
      }
      responsible_user {
        __typename
        id
        first_name
        last_name
      }
      progress
      completed
      created_at
      report_sub_buildings(order_by: { sub_building: { building: { address: { street: asc } } } }) {
        ...ReportGetReportsSubBuildings
      }
    }
  }
`);

export const GET_TEMPLATES = graphql(`
  query CreateReportGetAllTemplates {
    report_template: getAllLatestReportTemplates {
      ...CreateReportModalGetAllTemplatesTemplate
    }
  }
`);

export const GET_USERS = graphql(`
  query CreateReportModalAllUsers {
    user {
      ...ReportGetReportByPkResponsibleUser
    }
  }
`);

export const GET_SUB_BUILDINGS = graphql(`
  query CreateReportModalSubBuildingsInPortfolio($portfolio_id: Int!) {
    sub_building(where: { building: { economic_unit: { portfolio_id: { _eq: $portfolio_id } } } }) {
      __typename
      id
      building {
        address {
          street
          city
          postal_code
        }
        areas(where: { area_type_id: { _eq: NUF } }) {
          id
          area_type_id
          class_of_use_id
          type_of_use_id
        }
      }
    }
  }
`);

graphql(`
  fragment ReportAnswerForAutofill on report_answer {
    sub_building_id
    autofill
    report_answer_autofill_options {
      report_template_question_option {
        id
      }
    }
    report_question_id
  }
`);

export const GET_ALL_REPORT_AUTOFILL_QUESTIONS = graphql(`
  query AllReportAutofillQuestions($reportId: Int!) {
    report_by_pk(id: $reportId) {
      # filters all autofill-able answers - this is the current way to detect autofill questions from a template.
      report_answers(
        where: {
          _and: [
            # Here actually all questions that require evidence are already filtered out since they can not be autofilled.
            { state: { _eq: UNANSWERED }, report_template_question: { evidence_required: { _neq: true } } }
            {
              _or: [
                { autofill: { _is_null: false } }
                { report_answer_autofill_options: { report_template_question_option: { value: { _is_null: false } } } }
              ]
            }
          ]
        }
      ) {
        ...ReportAnswerForAutofill
      }
    }
  }
`);

export const GET_REPORT_COMPLETION = graphql(`
  query GetReportCompletion($id: Int!) {
    report_by_pk(id: $id) {
      id
      __typename
      progress
      completed
    }
  }
`);

graphql(`
  fragment ReportAnswersState on report_answer {
    id
    sub_building_id
    report_template_question {
      report_template_section {
        id
      }
    }
    state
  }
`);

export const GET_SECTIONS_ANSWER_COUNT = graphql(`
  query GetSectionsAnswerCount($reportId: Int!) {
    report_answer(where: { report_id: { _eq: $reportId } }) {
      ...ReportAnswersState
    }
  }
`);

graphql(`
  fragment ReportSectionsProgress on report_section_progress {
    id
    report_template_section_id
    sub_building_id
    progress
  }
`);

export const GET_SECTIONS_PROGRESS = graphql(`
  query GetSectionsProgress($reportId: Int!) {
    report_section_progress(where: { report_id: { _eq: $reportId } }) {
      ...ReportSectionsProgress
    }
  }
`);

export const GET_OPTIONS_WITH_SUBQUESTIONS = graphql(`
  query ReportQuestionGetOptionsWithSubquestions($questionId: Int!) {
    report_template_question_by_pk(id: $questionId) {
      id
      report_template_question_options {
        __typename
        id
        order
        value
        report_template_child_questions {
          __typename
          id
          type
          question
          order
          parent_option_id
        }
      }
    }
  }
`);

graphql(`
  fragment SubsectionProgress on report_section_progress {
    __typename
    progress
    report_template_section {
      id
      target
    }
  }
`);

export const SUBSECTIONS_PROGRESS = graphql(`
  query SubsectionsProgress($reportId: Int!) {
    report_by_pk(id: $reportId) {
      __typename
      id
      report_sub_buildings {
        sub_building_id
        report_sections_progress {
          ...SubsectionProgress
        }
      }
      report_sections_progress {
        ...SubsectionProgress
      }
    }
  }
`);

export const GET_ROOT_QUESTIONS_AND_ANSWERS = graphql(`
  query ReportSectionGetRootQuestionsAndAnswers(
    $sectionId: Int!
    $reportId: Int!
    $subBuildingWhere: Int_comparison_exp = {}
  ) {
    report_by_pk(id: $reportId) {
      report_answers(
        where: {
          report_template_question: { parent_section_id: { _eq: $sectionId } }
          sub_building_id: $subBuildingWhere
        }
        order_by: { report_template_question: { order: asc } }
      ) {
        ...ReportSectionGetRootQuestionsAndAnswersAnswers
      }
    }
  }
`);

export const GET_ROOT_QUESTION_AND_ANSWER = graphql(`
  query ReportSectionGetRootQuestionAndAnswer($questionId: Int!, $reportId: Int!) {
    report_template_question_by_pk(id: $questionId) {
      report_answers(where: { report_id: { _eq: $reportId } }, order_by: { report_template_question: { order: asc } }) {
        ...ReportSectionGetRootQuestionsAndAnswersAnswers
      }
    }
  }
`);

export const GET_QUESTION_ANSWER = graphql(`
  query GET_NESTED_QUESTION_ANSWER($reportId: Int!, $questionId: Int!) {
    report_by_pk(id: $reportId) {
      report_answers(where: { report_question_id: { _eq: $questionId } }) {
        ...ReportSectionGetRootQuestionsAndAnswersAnswers
      }
    }
  }
`);

export const REPORT_HEAD_GET_SCORES = graphql(`
  subscription ReportHeadGetReportScores($reportId: Int!) {
    report_by_pk(id: $reportId) {
      report_template {
        report_template_scores(where: { display: { _eq: true } }, order_by: { order: asc }) {
          __typename
          id
          name
          display_type
          max_score
          report_scores(where: { report_id: { _eq: $reportId } }) {
            value
          }
        }
      }
    }
  }
`);

export const REPORT_HEAD_GET_PROGRESS = graphql(`
  query ReportHeadGetReportProgress($reportId: Int!) {
    report_by_pk(id: $reportId) {
      __typename
      id
      progress
      report_sections_progress(
        where: {
          _and: {
            report_template_section: { parent_section_id: { _is_null: true } }
            sub_building_id: { _is_null: true }
          }
        }
        order_by: { report_template_section: { order: asc } }
      ) {
        progress
        report_template_section {
          id
          name
        }
      }
    }
  }
`);

// EVIDENCES

export const GET_REPORT_EVIDENCES = graphql(`
  query GET_ALL_EVIDENCES($reportId: Int!) {
    report_evidence(where: { report_id: { _eq: $reportId } }) {
      __typename
      id
      file {
        __typename
        id
        name
        downloadUrl
      }
      url
    }
  }
`);

export const GET_QUESTION_EVIDENCES = graphql(`
  query GET_QUESTION_EVIDENCES($reportId: Int!, $questionId: Int!) {
    report_question_evidence(
      where: {
        _and: [
          { report_evidence: { report_id: { _eq: $reportId } } }
          { report_template_question_id: { _eq: $questionId } }
        ]
      }
      order_by: { created_at: asc }
    ) {
      __typename
      id
      description
      report_evidence {
        __typename
        id
        file {
          id
          name
          downloadUrl
        }
        url
      }
    }
  }
`);

export const GET_All_EVIDENCES = graphql(`
  query GET_All_EVIDENCES($reportId: Int!) {
    report_evidence(where: { report_id: { _eq: $reportId } }) {
      __typename
      id
      file {
        id
        name
        downloadUrl
      }
      url
      report_question_evidences {
        id
        report_template_question {
          id
          question
        }
      }
    }
  }
`);

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import NavigationSelector from './NavigationMultiSelector';

export type AutoCompleteValue = {
  value: number | string;
  label: string;
};

type Props = {
  portfolios: EsgAnalysisPortfolioFragment[];
  filtersComponent: ReactNode;
};

export const ALL_PORTFOLIOS = 'all_portfolios';

export default function PortfolioSelector({ portfolios, filtersComponent }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const { filters, resetFilter, replaceFilter } = useEsgAnalysisFilters();

  /**
   * Creates each option for the portfolio select
   */
  const mapToPortfolioSelectOption = (portfolio: EsgAnalysisPortfolioFragment): AutoCompleteValue => {
    return {
      value: portfolio.id,
      label: portfolio.name ?? t('EsgAnalysisFilterBarPortfolio_NoName_WithId', { portfolioId: portfolio.id }),
    };
  };

  const getPortfoliosFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return portfolios.filter((portfolio) => ids.includes(portfolio.id)).map(mapToPortfolioSelectOption);
  };

  const onPortfolioSelected = (selectedPortfolios: AutoCompleteValue[]) => {
    const previousSelectedPortfolios = getPortfoliosFromIds(filters.portfolioIds);

    const newlySelectedPortfolio: AutoCompleteValue[] | undefined =
      previousSelectedPortfolios &&
      selectedPortfolios.filter(
        (portfolio) => !previousSelectedPortfolios.some((prevPortfolio) => prevPortfolio.value === portfolio.value),
      );

    if (newlySelectedPortfolio && newlySelectedPortfolio.length > 0) {
      trackEvent('PORTFOLIO_SELECTED', {
        portfolio_id: newlySelectedPortfolio[0].value,
      });
    }

    replaceFilter(
      EsgAnalysisFiltersKeys.portfolioIds,
      selectedPortfolios.map((portfolio) => portfolio.value as number),
    );
  };

  const portfolioOptions = portfolios.map(mapToPortfolioSelectOption);

  const filteredPortfolios =
    filters.portfolioIds && filters.portfolioIds.length > 0 ? getPortfoliosFromIds(filters.portfolioIds) : [];

  const count = filteredPortfolios.length;

  const selectorLabel =
    filteredPortfolios.length > 1
      ? `${count} ${t('General_Portfolio', {
          count: count,
        })}`
      : filteredPortfolios[0]?.label ?? t('General_AllPortfolios');

  return (
    <NavigationSelector
      onOptionSelected={onPortfolioSelected}
      options={portfolioOptions}
      filtersComponent={filtersComponent}
      filteredItems={filteredPortfolios}
      selectorLabel={selectorLabel}
      noDataText={t('EsgAnalysisFilterBarPortfolio_NoPortfoliosFound')}
      handleClear={() => {
        resetFilter(EsgAnalysisFiltersKeys.portfolioIds);
        resetFilter(EsgAnalysisFiltersKeys.buildingIds);
        trackEvent('PORTFOLIO_SELECTED', {
          portfolio_id: ALL_PORTFOLIOS,
        });
      }}
      placeholder={t('General_SearchPortfolioAction')}
    />
  );
}

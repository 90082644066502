import { useQuery } from '@apollo/client';
import { Card, Grid } from '@mui/material';
import { EsgAnalysisGetPortfolioQuery, EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import { pathValueAtYear } from '@predium/client-lookup';
import { energy_source_type_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DelayedLoading } from '../../components/Loading';
import SimpleComparisonMetric from '../../components/SimpleComparisonMetric';
import Unavailable from '../../components/Unavailable';
import BuildingsByEnergySourceType from '../../components/data-visialization/esg-analysis/BuildingsByEnergySourceTypeGraph';
import CO2CostDevelopmentGraph from '../../components/data-visialization/esg-analysis/CO2CostDevelopmentGraph';
import EnergyCostDevelopmentGraph from '../../components/data-visialization/esg-analysis/EnergyCostDevelopmentGraph';
import { EnergySourceConsumptionGraph } from '../../components/data-visialization/esg-analysis/EnergySourceGraph';
import EuTaxonomyGraph from '../../components/data-visialization/esg-analysis/EuTaxonomyGraph';
import {
  PortfolioCO2CostDistributionGraph,
  PortfolioEfficiencyClassDistributionGraph,
} from '../../components/data-visialization/esg-analysis/PortfolioBuildingDistributionGraph';
import Scope123Graph from '../../components/data-visialization/esg-analysis/Scope123Graph';
import { GET_BENCHMARK } from '../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../hooks/useEsgAnalysisFilters';
import { PATHS } from '../../routes';
import { DataCollectionTabActionsEnum } from '../DataCollection/Buildings/TabsEnums';
import { determineStartYear } from '../Scenarios/ActionPlan/ActionPlan.utils';
import { ENTIRE_COMPANY } from './Components/EsgAnalysisBenchmark';
import { CrremAnalysis } from './DemandAnalysis/CrremAnalysis';
import { EsgAnalysisFiltersKeys } from './EsgAnalysisFilters/EsgAnalysisFilters';
import { CurrentPageView } from './Portfolio/PortfolioAnalysisHeader';
import AreaBubbleChart from './Visualization/AreaBubbleChart';

type Props = {
  benchmarkId: number;
  showTotal: boolean;
  portfolios: EsgAnalysisPortfolioFragment[];
  constructionYear: number;
  currentPageView?: CurrentPageView;
  analyzedBuildings: EsgAnalysisGetPortfolioQuery['getAnalyzedBuildings'] | undefined;
  loading: boolean;
};

export default function EsgAnalysisSection({
  benchmarkId,
  showTotal,
  portfolios,
  constructionYear,
  currentPageView = 'esg-portfolio',
  analyzedBuildings,
  loading,
}: Props) {
  const { t } = useTranslation();
  const { setFilter } = useEsgAnalysisFilters();
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();

  const { data: benchmarkData, loading: benchmarkLoading } = useQuery(GET_BENCHMARK, {
    variables: {
      portfolioId: benchmarkId,
    },
  });

  const benchmarks = useMemo(
    () => [
      { id: ENTIRE_COMPANY, name: t('EsgAnalysisBenchmark_EntireCompany') },
      ...portfolios.map((portfolio) => ({
        id: portfolio.id,
        name: `Portfolio ${portfolio.name}`,
      })),
    ],
    [portfolios, t],
  );

  if (benchmarkLoading || (loading && !analyzedBuildings)) {
    return <DelayedLoading />;
  }

  if (!benchmarkData || !analyzedBuildings) {
    return (
      <Unavailable
        title={t('General_BuildingsUnavailable-title')}
        subTitle={t('ESGAnalysisSection_AddFirstBuildingToPortfolio')}
        onClick={() =>
          navigate(
            PATHS.dataCollection.buildings(null, {
              action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
            }),
          )
        }
      />
    );
  }

  const calculateBenchmarkChange = (value: number, benchmarkValue?: number) => {
    if (!benchmarkValue) return null;
    return (1 - value / benchmarkValue) * -1;
  };

  const handleEnergySourceSelect = (value: energy_source_type_enum) => {
    setFilter(EsgAnalysisFiltersKeys.energySourceTypes, value);
  };

  const benchmark = benchmarkData.getBenchmark;

  if (analyzedBuildings.analyzedBuildingsCount === 0) {
    return (
      <Unavailable
        title={t('ESGAnalysisSection_FilterNotApplicable')}
        subTitle={t('ESGAnalysisSection_ChangeFilterToStartAnalysis')}
      />
    );
  }

  const series = analyzedBuildings?.floorAreaBySubBuildingClass.map((group) => group.area);
  const totalArea = sum(series);

  const co2EmissionsChange = calculateBenchmarkChange(
    analyzedBuildings.averageCo2Emissions,
    benchmark?.averageCo2Emissions,
  );

  const co2Costs = pathValueAtYear(analyzedBuildings.co2CostPath.m2, currentYear);
  const co2CostsChange = calculateBenchmarkChange(co2Costs, benchmark?.averageCo2Costs);

  const energyCosts = pathValueAtYear(analyzedBuildings.energyCostPath.m2, currentYear);
  const energyCostsChange = calculateBenchmarkChange(energyCosts, benchmark?.averageEnergyCosts);

  const finalEnergyChange = calculateBenchmarkChange(
    analyzedBuildings.averageFinalEnergy,
    benchmark?.averageFinalEnergy,
  );
  const primaryEnergyChange = calculateBenchmarkChange(
    analyzedBuildings.averagePrimaryEnergy,
    benchmark?.averagePrimaryEnergy,
  );

  const heatingDemand = analyzedBuildings.averageHeatingDemand;

  const heatingDemandChange =
    heatingDemand && benchmark?.averageHeatingDemand
      ? (1 - heatingDemand / benchmark?.averageHeatingDemand) * -1
      : null;

  const currentEnergyDataBySource = analyzedBuildings.energyDataBySourceType;

  const energySources = currentEnergyDataBySource.map((energySource) => ({
    energy_source_type: energySource.energySourceType,
    energy_consumption: energySource.energyConsumptionM2,
    energy_consumption_total: energySource.energyConsumptionTotal,
  }));

  const scope123Data = pathValueAtYear(analyzedBuildings.scope123Co2Emissions, currentYear);
  const buildingsByEnergySource = analyzedBuildings.buildingsByEnergySourceType;
  const buildingsByEUTaxonomy = analyzedBuildings.buildingsByEUTaxonomyCompliance;

  const selectedBenchmarkName = ensureDefined(benchmarks.find((benchmark) => benchmark.id === benchmarkId)).name;

  const hasHeatingDemand = !!heatingDemand;
  const firstContainerSize = hasHeatingDemand ? 10 : 8;
  const itemSizeFirstContainer = hasHeatingDemand ? 3 : 4;

  const { averageFinalEnergy, averagePrimaryEnergy, averageCo2Emissions } = analyzedBuildings;

  const graphStartYear: number = determineStartYear(constructionYear);

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid item xs={firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_FinalEnergy')}
                  value={showTotal ? averageFinalEnergy * totalArea : averageFinalEnergy}
                  change={finalEnergyChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="kWh"
                  showTotal={showTotal}
                />
              </Grid>
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_PrimaryEnergy')}
                  value={showTotal ? averagePrimaryEnergy * totalArea : averagePrimaryEnergy}
                  change={primaryEnergyChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="kWh"
                  showTotal={showTotal}
                />
              </Grid>
              {hasHeatingDemand && (
                <Grid item xs={3}>
                  <SimpleComparisonMetric
                    title={t('General_HeatingDemand')}
                    value={showTotal ? heatingDemand * totalArea : heatingDemand}
                    change={heatingDemandChange}
                    hideNoChange={heatingDemandChange === null}
                    selectedBenchmarkName={selectedBenchmarkName}
                    unit="kWh"
                    showTotal={showTotal}
                  />
                </Grid>
              )}
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_CO2Intensity')}
                  value={showTotal ? averageCo2Emissions * totalArea : averageCo2Emissions}
                  change={co2EmissionsChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="kg CO₂e"
                  showTotal={showTotal}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12 - firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={hasHeatingDemand ? 12 : 6}>
                <SimpleComparisonMetric
                  title={t('General_EnergyCost')}
                  value={showTotal ? energyCosts * totalArea : energyCosts}
                  change={energyCostsChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit="€"
                  showTotal={showTotal}
                />
              </Grid>
              {!hasHeatingDemand && (
                <Grid item xs={6}>
                  <SimpleComparisonMetric
                    title={t('General_CO2Taxes')}
                    value={showTotal ? co2Costs * totalArea : co2Costs}
                    change={co2CostsChange}
                    selectedBenchmarkName={selectedBenchmarkName}
                    unit="€"
                    showTotal={showTotal}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CrremAnalysis
          buildingLegendName={t('General_Portfolio')}
          cumulativeExcessEmissions={analyzedBuildings.crremAnalysis.totalCumulativeExcessEmissions}
          graphStartYear={graphStartYear}
          selectedBenchmarkName={selectedBenchmarkName}
          benchmarkCrremAnalysis={benchmark.crremAnalysis}
          crremAnalysis={analyzedBuildings.crremAnalysis}
        />
      </Grid>
      {currentPageView === 'esg-portfolio' && (
        <Grid item xs={12}>
          <AreaBubbleChart buildings={analyzedBuildings.buildingsGeneralData} />
        </Grid>
      )}
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Scope123Graph scopeData={scope123Data.data} showTotal={showTotal} />
        </Grid>
        <Grid item xs={6}>
          <EnergySourceConsumptionGraph
            energySources={energySources}
            showTotal={showTotal}
            totalArea={totalArea}
            onItemSelect={handleEnergySourceSelect}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <CO2CostDevelopmentGraph
              co2Costs={analyzedBuildings.co2CostPath.m2}
              co2CostsTotal={analyzedBuildings.co2CostPath.total}
              landlordProportion={analyzedBuildings.averageTaxBracket.landlordProportion}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergyCostDevelopmentGraph
              energyCosts={analyzedBuildings.energyCostPath.m2}
              energyCostsTotal={analyzedBuildings.energyCostPath.total}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <PortfolioCO2CostDistributionGraph buildingsByTaxBracket={analyzedBuildings.buildingsByTaxBracket} />
        </Grid>
        <Grid item xs={6}>
          <PortfolioEfficiencyClassDistributionGraph
            buildingsByEfficiencyClass={analyzedBuildings.buildingsByEfficiencyClass}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <EuTaxonomyGraph scopeData={buildingsByEUTaxonomy} />
        </Grid>
        <Grid item xs={6}>
          <BuildingsByEnergySourceType data={buildingsByEnergySource} />
        </Grid>
      </Grid>
    </Grid>
  );
}

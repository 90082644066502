import { country_enum } from '@predium/enums';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR } from '../../targetPaths/TargetPathConstants';
import { RegionCode, Year, electricityEF, getRegionIndex, regionLookup } from './crrem-normalized.lookups';

//
// INFO: all the magic numbers come from the CRREM report found in this file:
// https://docs.google.com/spreadsheets/d/1X4Q6-kM9z5BkJnKw8Pi4hVTOmaNxZODK/edit?usp=drive_link&ouid=116501179550330248234&rtpof=true&sd=true
//

export const ShareofCountryLookup: Record<
  country_enum,
  {
    shareOfElectricityUsedForHeating: number;
    shareOfElectricityUsedForCooling: number;
    shareOfFossilFuelHeating: number;
  }
> = {
  [country_enum.DE]: {
    shareOfElectricityUsedForHeating: 0.0363771648865648,
    shareOfElectricityUsedForCooling: 0.0199242149416744,
    shareOfFossilFuelHeating: 0.773016057524304,
  },
  [country_enum.AT]: {
    shareOfElectricityUsedForHeating: 0.0523369391715243,
    shareOfElectricityUsedForCooling: 0.0665690088783187,
    shareOfFossilFuelHeating: 0.81175276505003,
  },
  [country_enum.PL]: {
    shareOfElectricityUsedForHeating: 0.0931741232146898,
    shareOfElectricityUsedForCooling: 0.1016079442702714,
    shareOfFossilFuelHeating: 0.79105871974194,
  },
};

/**
 * Helper function to get the CRREM region code for a given country and postal code
 *
 * @export
 * @param {country_enum} country
 * @param {string} postalCode
 * @return {*}  {string}
 */
export function getRegionCode(country: country_enum, postalCode: string): RegionCode {
  const returnDefaultForCountry = () => {
    console.error(
      `CRREM getRegionCode Emission Factor Provider was called with unsupported country ${country} and postal code ${postalCode}`,
    );
    if (country === country_enum.AT) {
      return 'AT' as RegionCode;
    } else if (country === country_enum.PL) {
      return 'PL' as RegionCode;
    } else {
      return 'DE' as RegionCode;
    }
  };

  if (country === country_enum.DE || country === country_enum.AT) {
    const strippedPostalCode = postalCode.replace(/^0+/, ''); // Strip leading zeros
    const lookupKey = (country + strippedPostalCode) as keyof typeof regionLookup;

    if (!regionLookup[lookupKey]) {
      return returnDefaultForCountry();
    }

    return regionLookup[lookupKey];
  } else if (country === country_enum.PL) {
    let strippedPostalCode = postalCode.replace(/—|–/g, '-');

    // If no dash is present, add a short dash at position 3
    if (!strippedPostalCode.includes('-')) {
      strippedPostalCode = postalCode.slice(0, 2) + '-' + postalCode.slice(2);
    }

    const lookupKey = (country + strippedPostalCode) as keyof typeof regionLookup;
    if (!regionLookup[lookupKey]) {
      return returnDefaultForCountry();
    }
    return regionLookup[lookupKey];
  } else {
    return returnDefaultForCountry();
  }
}

/**
 * Get the normalized electricity emission factor for a given year, region and country
 *
 * @export
 * @param {number} year
 * @param {string} regionCode
 * @param {country_enum} country
 * @param {number} energyFinal
 * @return {*}  {number}
 */
export function crremNormalizedElectricity(year: number, regionCode: RegionCode, country: country_enum): number {
  if (year > TARGET_PATH_LAST_YEAR || year < TARGET_PATH_FIRST_YEAR) {
    console.error(`CRREM crremNormalizedElectricity Emission Factor Provider was called with unsupported year ${year}`);
    throw new Error(`Year ${year} is not supported by CRREM Normalized Emission Factor Provider`);
  }

  // One of our assumptions is that AC=No in all CRREM calculations
  const AC13__hasAirConditioning = false;

  return (
    (gridIndex(year, country) / gridIndex(reportingYear(), country)) *
    (1 +
      ShareofCountryLookup[country].shareOfElectricityUsedForHeating * (heatingIndex(year, regionCode, country) - 1) +
      /* @see formula in CRREMBackendWorksheet#MI3_NM3__totalEmissions */
      (!AC13__hasAirConditioning
        ? 0
        : ShareofCountryLookup[country].shareOfElectricityUsedForCooling *
          (coolingIndex(year, regionCode, country) - 1)))
  );
}

/**
 * Get the normalized gas, oil and other emission factor for a given year and region
 *
 * @export
 * @param {number} year
 * @param {string} regionCode
 * @return {*}  {number}
 */
export function crremNormalizedGasOilOther(year: number, country: country_enum, regionCode: RegionCode): number {
  if (year > TARGET_PATH_LAST_YEAR || year < TARGET_PATH_FIRST_YEAR) {
    console.error(`CRREM crremNormalizedGasOilOther Emission Factor Provider was called with unsupported year ${year}`);
    throw new Error(`Year ${year} is not supported by CRREM Normalized Emission Factor Provider`);
  }

  return (
    (heatingIndex(year, regionCode, country) / heatingIndex(reportingYear(), regionCode, country)) *
    (1 + ShareofCountryLookup[country].shareOfFossilFuelHeating * (heatingIndex(year, regionCode, country) - 1))
  );
}

// INFO: might be needed in the future
// export function crremNormalizedDistrictCooling(year, regionCode): number {
//   return coolingIndex(year, regionCode) / coolingIndex(reportingYear(), regionCode);
// }

/**
 * Get the normalized district heating emission factor for a given year, region and country
 *
 * @export
 * @param {*} year
 * @param {*} regionCode
 * @param {*} country
 * @return {*}  {number}
 */
export function crremNormalizedDistrictHeating(year: number, regionCode: RegionCode, country: country_enum): number {
  if (year > TARGET_PATH_LAST_YEAR || year < TARGET_PATH_FIRST_YEAR) {
    console.error(
      `CRREM crremNormalizedDistrictHeating Emission Factor Provider was called with unsupported year ${year}`,
    );
    throw new Error(`Year ${year} is not supported by CRREM Normalized Emission Factor Provider`);
  }
  return heatingIndex(year, regionCode, country);
}

//
// PRIVATE FUNCTIONS
//

//this is a function and not a file scope variable to make it sylvester-safe.
// otherwise the year would stay the same even when we cross the year boundary over new year as it was set at the beginning of the program
function reportingYear(): number {
  return TARGET_PATH_FIRST_YEAR;
}

function heatingIndex(year: number, regionCode: RegionCode, country: country_enum): number {
  const projection = 'RCP4.5';

  const index = getRegionIndex(regionCode, country);
  const calculatedYearIndex = yearIndex(year);

  if (projection == 'RCP4.5') {
    return (index.HDD_2015 + calculatedYearIndex * index.HDD_45_pa) / (index.HDD_2015 + 1 * index.HDD_45_pa);
  } else {
    return (index.HDD_2015 + calculatedYearIndex * index.HDD_85_pa) / (index.HDD_2015 + 1 * index.HDD_85_pa);
  }
}

function coolingIndex(year: number, regionCode: RegionCode, country: country_enum): number {
  const projection = 'RCP4.5';

  const index = getRegionIndex(regionCode, country);
  const calculatedYearIndex = yearIndex(year);

  if (projection == 'RCP4.5') {
    return (index.CDD_2015 + calculatedYearIndex * index.CDD_45_pa) / (index.CDD_2015 + 1 * index.CDD_45_pa);
  } else {
    return (index.CDD_2015 + calculatedYearIndex * index.CDD_85_pa) / (index.CDD_2015 + 1 * index.CDD_85_pa);
  }
}

function gridIndex(year: number, country: country_enum): number {
  if (reportingYear() >= year) {
    return 1;
  } else {
    return electricityEF[country][year as unknown as Year] / electricityEF[country][reportingYear() as unknown as Year];
  }
}

function yearIndex(year: number): number {
  return Math.max(1 + year - reportingYear(), 0);
}

export function getRenewableDistrictHeatingEmissionFactor(country: country_enum): number {
  switch (country) {
    case country_enum.DE:
      return 0.05613427417198402;
    case country_enum.AT:
      return 0.02835889176495527;
    case country_enum.PL:
      return 0.07631790789848127;
    default: {
      const exhaustiveCheck: never = country;
      throw new Error(`Unhandled country ${country}: ${exhaustiveCheck}`);
    }
  }
}

import { Box, Divider, Stack, Tab, Tabs } from '@mui/material';
import {
  EsgAnalysisGetBuildingsForSingleBuildingFragment,
  EsgAnalysisPortfolioFragment,
} from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { ESG_TAB_ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import EsgAnalysisBenchmark from '../Components/EsgAnalysisBenchmark';
import EsgAnalysisUnitSwitcher from '../Components/EsgAnalysisUnitSwitcher';
import ReportingYearSelector from '../Components/ReportingYearSelector';
import { EsgAnalysisTab } from '../Portfolio/PortfolioAnalysisHeader';

type Props = {
  portfolios: EsgAnalysisPortfolioFragment[];
  selectedBuilding: EsgAnalysisGetBuildingsForSingleBuildingFragment;
  currentTab: EsgAnalysisTab;
  setCurrentTab: (tab: EsgAnalysisTab) => void;
  setShowTotal: (showTotal: boolean) => void;
  setSelectedReportingYear: (year: number) => void;
  selectedReportingYear: number;
  benchmarkId: number | undefined;
  setBenchmarkId: (benchmarkId: number) => void;
};

export default function EsgAnalysisBuildingTabs({
  selectedBuilding,
  portfolios,
  currentTab,
  setCurrentTab,
  setShowTotal,
  setSelectedReportingYear,
  selectedReportingYear,
  setBenchmarkId,
  benchmarkId,
}: Props) {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { serverSideFeatureFlags } = useSessionData();

  const isConsumptionAnalysisEnabled = serverSideFeatureFlags.CONSUMPTION_ANALYSIS;

  const tabs: {
    label: string;
    value: EsgAnalysisTab;
    icon: string;
  }[] = [
    {
      label: t('General_Demand'),
      value: 'demand',

      icon: ESG_TAB_ICONS.DEMAND,
    },
    {
      label: t('General_ClimateRisks'),
      value: 'climate_risks',

      icon: ESG_TAB_ICONS.CLIMATE_RISKS,
    },
  ];

  if (isConsumptionAnalysisEnabled) {
    tabs.splice(1, 0, {
      label: t('General_Consumption'),
      value: 'consumption',

      icon: ESG_TAB_ICONS.CONSUMPTION,
    });
  }

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolute') => {
    const doShowTotal = newDisplayBase === 'absolute';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => setCurrentTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{ mb: 1 }}
              icon={<Iconify icon={tab.icon} width={24} height={24} />}
            />
          ))}
        </Tabs>

        {currentTab !== 'climate_risks' && (
          <Stack direction={'row'} spacing={2}>
            {currentTab !== 'consumption' && (
              <EsgAnalysisBenchmark portfolios={portfolios} initialId={benchmarkId} setBenchmarkId={setBenchmarkId} />
            )}
            <EsgAnalysisUnitSwitcher
              onDisplayBaseChange={onDisplayBaseChange}
              isConsumptionAnalysis={currentTab === 'consumption'}
            />
            {isConsumptionAnalysisEnabled && currentTab !== 'demand' && (
              <ReportingYearSelector
                setSelectedReportingYear={setSelectedReportingYear}
                selectedReportingYear={selectedReportingYear}
                constructionYear={selectedBuilding.year_constructed}
              />
            )}
          </Stack>
        )}
      </Stack>

      <Divider />
    </Box>
  );
}
